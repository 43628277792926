import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTable, { TableColumn } from 'react-data-table-component';

import { ADMIN_PENDING_QUERY } from 'shared-components/queries/graphql';
import { PendingPaymentType } from '../../../types/grapql';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';

const Pending = () => {
  const { merchantId } = useParams();
  const [data, setData] = useState<PendingPaymentType[]>([]);
  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'merchantPending',
    data: {
      query: ADMIN_PENDING_QUERY,
      variables: { id: merchantId }
    },
    auto: false,
    onResponse: (data: any) => {
      setData(data);
    }
  });

  useEffect(() => {
    query.execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: TableColumn<PendingPaymentType>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row) => row.id || '',
        wrap: true
        //minWidth: '132px'
      },
      {
        name: 'TXREF',
        selector: (row) => row.txRef ?? '',
        wrap: true
      },
      {
        name: 'EMAIL',
        selector: (row) => row.email ?? '',
        wrap: true
        //minWidth: '150px'
      },
      {
        name: 'IBAN',
        selector: (row) => row.iban ?? '',
        wrap: true
      },
      {
        name: 'STATUS',
        selector: (row) => row.status ?? '',
        wrap: true,
        width: '81px'
      },
      {
        name: 'BUY RATE',
        selector: (row) => row.buyRate ?? '',
        wrap: true
      },
      {
        name: 'SALE RATE',
        selector: (row) => row.sellRate ?? '',
        wrap: true
      },
      {
        name: 'SEK DEPOSIT',
        selector: (row) => row.sekDeposit ?? '',
        wrap: true
      },

      {
        name: 'EUR REQUEST',
        selector: (row) => row.eurRequest ?? '',
        wrap: true,
        width: '120px'
      },

      {
        name: 'CREATED AT',
        selector: (row) => row.createdAt ?? '',
        wrap: true,
        width: '180px'
      },

      {
        name: 'SETTLED AT',
        selector: (row) => row.settledAt ?? '',
        wrap: true,
        width: '180px'
      }
    ],
    []
  );

  return (
    <DataTable
      highlightOnHover={true}
      columns={columns}
      data={data ?? []}
      customStyles={{
        headCells: {
          style: {
            backgroundColor: 'lightgray',
            textTransform: 'uppercase'
          }
        }
      }}
      className="shadow p-3 mb-5 bg-white overflow-auto"
    />
  );
};
export default Pending;
