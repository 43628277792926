import { Card, Col, Row, Table } from 'react-bootstrap';
import React, { Key, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

import { AdminUrl, MerchantPrefix, Method, Service } from '../../../shared-components/configuration';
import Button from '../../../shared-components/components/Button';
import ConfirmModal from '../../../components/Modals/Confirm';
import useApiKeys from 'shared-components/hooks/useApiKeys';
import { useApp } from 'shared-components/providers/AppProvider';
import { REVOKE_API_KEY } from 'shared-components/queries/mutations';
import useSWQuery from 'shared-components/hooks/useSWQuery';

const ApiKeys = () => {
  const keys = useApiKeys();
  const {
    setters: { setProtected }
  } = useApp();
  useEffect(() => {
    setProtected(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const revoke = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'revokeApiKey',
    data: {},
    auto: false,
    onResponse: (_: any) => {
      keys.refresh();
    }
  });

  const [selectedRevokeKey, setSelectedRevokeKey] = useState('');

  const onRevokeApiKey = (key: string) => {
    revoke.execute({ query: REVOKE_API_KEY(key) });
    setSelectedRevokeKey('');
    // do something
  };

  return (
    <>
      <ConfirmModal
        visible={!!selectedRevokeKey}
        title="Are you really sure to revoke the key"
        onOk={() => onRevokeApiKey(selectedRevokeKey)}
        handleClose={() => {
          setSelectedRevokeKey('');
        }}
      />
      <Card className="rounded-1  pt-3 p-2">
        <Row>
          <Col>
            <h2>API Keys</h2>
          </Col>
          <Col sm={4}>
            <Button href={`${MerchantPrefix}/${AdminUrl.API_KEYS_ADD}`} style={{ float: 'right' }}>
              Add API key
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            This page provides management tools for your API keys, enabling access to our Merchant API through
            third-party services. We specialize in supporting asymmetric EC (Elliptic Curve) keys. To ensure security,
            it&apos;s mandatory to sign your public key using your AuthID application. For a comprehensive guide,
            including step-by-step instructions and security protocols, please refer to the &apos;Authentication&apos;
            section in our API documentation.
          </Col>
        </Row>
        <br />
        <Table hover>
          <thead>
            <tr></tr>
            <tr>
              <th></th>
              <th>Key</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {keys.keys?.map((element: any, index: Key | null | undefined) => {
              return (
                <tr key={index}>
                  <td>
                    <center>
                      <FontAwesomeIcon icon={faKey} />
                      <br />
                      EC
                    </center>
                  </td>
                  <td>
                    SHA256: {element.key}
                    <br />
                    Added: {new Date().toDateString()}
                    <br />
                    Last used: {new Date().toDateString()}
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}></td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};
export default ApiKeys;
/*
                    <Button variant="outline-danger" onClick={() => setSelectedRevokeKey(element.key)}>
                      Revoke key
                    </Button>
*/
