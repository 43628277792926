import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import React from 'react';

import { clsx } from 'clsx';

//import { useApp } from 'shared-components/providers/AppProvider';
import { LogoFCSvg } from '../../../assets/icons';
import Icon from '../../Icon';
import { PageUrl, merchantportalHost, settleHost } from '../../../configuration';

import style from './style.module.scss';

const HeaderDoc = () => {
  //const { userData, logout } = useApp();
  const hostname = window.location.hostname;
  /*
  const _logout = useCallback(() => {
    logout(true);
  }, [logout]);
*/
  return (
    <>
      <Navbar className={style.mainheader} expand="md">
        <Container fluid>
          <Navbar.Brand href="/">
            <Icon className={style.logo} icon={LogoFCSvg} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="shadow-none border-0" />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
            className={style.offcanvas}
          >
            <Offcanvas.Header closeButton className="justify-content-end"></Offcanvas.Header>
            <Offcanvas.Body className="justify-content-end">
              <Nav className={style.nav}>
                {/*userData.loggedIn ? (
                  <Nav.Link className={style.navlink} onClick={_logout}>
                    Logout
                  </Nav.Link>
                ) : (
                  <Nav.Link className={style.navlink} as={NavLink} to={'/login'}>
                    Login
                  </Nav.Link>
                )*/}

                <Nav.Link as={NavLink} to={`${settleHost}`} className={clsx(style.navlink)}>
                  Get settled
                </Nav.Link>
                <Nav.Item
                  className={clsx(style.navbutton)}
                  as={Button}
                  variant="outline-dark"
                  href={`${hostname.includes('localhost') ? '' : merchantportalHost}/${PageUrl.CONTACT_SALES}`}
                >
                  Contact Sales
                </Nav.Item>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderDoc;
