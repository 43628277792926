import React from 'react';

import HeaderDoc from './doc';
import HeaderMain from './main';

interface HeaderProps {
  type?: string;
}
const Header = ({ type }: HeaderProps) => {
  switch (type) {
    case 'doc': {
      return <HeaderDoc />;
    }
    default: {
      return <HeaderMain />;
    }
  }
};

export default Header;
