import React from 'react';

//import { useApp } from 'shared-components/providers/AppProvider';

import DashboardAdmin from './admin';
import DashboardUser from './merchant';

const Dashboard = () => {
  /*  const { userData } = useApp(); */
  // eslint-disable-next-line no-constant-condition
  return <>{/* userData.isAdmin */ false ? <DashboardAdmin /> : <DashboardUser />}</>;
};
export default Dashboard;
