import { Button, FloatingLabel, Form } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CentralFrame from '../../../components/CentralFrame';
import ContactSalesThxModal from '../../../components/Modals/ContactSalesThxModal';
import api from '../../../utils/api';
import ContactSalesErrorModal from '../../../components/Modals/ContactSalesErrorModal';

import style from './style.module.scss';

const ContactSales = () => {
  const [contactSalesModalShow, setContactSalesModalShow] = useState(false);
  const [contactSalesModalErrorShow, setContactSalesModalErrorShow] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);

  const navigate = useNavigate();

  const handleModalClose = useCallback(() => {
    setContactSalesModalShow(false);
    setSendDisabled(false);
    navigate('/');
  }, [navigate]);

  const handleModalErrorClose = useCallback(() => {
    setSendDisabled(false);
    setContactSalesModalErrorShow(false);
  }, []);

  const formSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      message: { value: string };
    };
    const name = target.name.value;
    const email = target.email.value;
    const message = target.message.value;
    setSendDisabled(true);
    api
      .signupMerchant(email, name, message)
      .then((response) => {
        if (response?.error) {
          setSendDisabled(false);
          setContactSalesModalErrorShow(true);
        } else {
          setSendDisabled(false);
          setContactSalesModalShow(true);
        }
      })
      .catch((_error: any) => {
        setSendDisabled(false);
        setContactSalesModalErrorShow(true);
      });
  }, []);

  return (
    <>
      <ContactSalesThxModal show={contactSalesModalShow} handleClose={handleModalClose}></ContactSalesThxModal>
      <ContactSalesErrorModal
        show={contactSalesModalErrorShow}
        handleClose={handleModalErrorClose}
      ></ContactSalesErrorModal>
      <CentralFrame>
        <h1 className={style.h1}>Contact sales</h1>
        <h2 className={style.h2}>Fill out the form to start your onboarding process</h2>
        <div className={style.divider}></div>
        <Form onSubmit={formSubmit}>
          <FloatingLabel controlId="contactSalesName" label="Company name" className={style.input_field}>
            <Form.Control name="name" type="text" placeholder="Enter your name" />
          </FloatingLabel>
          <FloatingLabel controlId="contactSalesEmail" label="Email" className={style.input_field}>
            <Form.Control name="email" type="email" placeholder="Your email address" />
          </FloatingLabel>
          <FloatingLabel controlId="contactSalesMessage" label="Message" className={style.input_field}>
            <Form.Control as="textarea" name="message" type="text" placeholder="Message" style={{ height: '200px' }} />
          </FloatingLabel>
          <div className="d-grid gap-2">
            <Button variant="primary" size="lg" type="submit" className={style.button} disabled={sendDisabled}>
              Continue
            </Button>
          </div>
        </Form>
      </CentralFrame>
    </>
  );
};

export default ContactSales;
