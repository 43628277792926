import { Col, Row } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable, { TableColumn } from 'react-data-table-component';

import Button from '../../../shared-components/components/Button';
import { useMerchants } from '../../../contexts/GraphQL';
import MerchantAddModal from '../../../components/Modals/MerchantAdd/merchantAdd';
import MerchantApproveModal from '../../../components/Modals/MerchantApprove/merchantApprove';
import { MerchantType } from '../../../types/grapql';
import { AdminPrefix, AdminUrl } from '../../../shared-components/configuration';
import SubMerchantAddModal from 'components/Modals/SubMerchantAdd/subMerchantAdd';

const Clients = () => {
  const [addMerchantVisible, setAddMerchantVisible] = useState(false);
  const [addSubMerchantVisible, setAddSubMerchantVisible] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const { merchants, refreshMerchants } = useMerchants();
  const navigate = useNavigate();

  const [selectedPendingMerchant, setSelectedPendingMerchant] = useState<MerchantType>();

  const handleCloseModal = useCallback(() => {
    setAddMerchantVisible(false);
  }, []);

  const handleSubCloseModal = useCallback(() => {
    setAddSubMerchantVisible(false);
  }, []);

  const handleCloseApproveModal = useCallback(() => {
    setSelectedPendingMerchant(undefined);
  }, []);

  const handleAddMerchant = useCallback(() => {
    setAddMerchantVisible(true);
  }, []);

  const handleAddMerchantSuccess = () => {
    refreshMerchants();
    setAddMerchantVisible(false);
  };

  const handleAddSubMerchantSuccess = () => {
    refreshMerchants();
    setAddSubMerchantVisible(false);
  };

  const handleApproveMerchantSuccess = () => {
    refreshMerchants();
    handleCloseApproveModal();
  };

  const handleOpenMerchantDetail = (merchant: MerchantType) => {
    navigate(`${AdminPrefix}/${AdminUrl.MERCHANTS}/${merchant.id}`);
  };

  const handleOpenSubMerchantModal = (merchant: MerchantType) => {
    if (merchant.id === undefined) {
      return;
    }
    setMerchantId(merchant.id ?? '');
    setAddSubMerchantVisible(true);
  };

  useEffect(() => {
    refreshMerchants();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: TableColumn<MerchantType>[] = [
    {
      name: 'COMPANY NAME',
      selector: (row) => row.name || '',
      wrap: true
      //      minWidth: '132px'
    },
    {
      name: 'IBAN',
      selector: (row) => row.iban ?? '',
      wrap: true
    },
    {
      name: 'FX FEE PERCENTAGE',
      selector: (row) => row.fxFee ?? '',
      wrap: true
    },
    {
      name: 'BUY FEE PERCENTAGE',
      selector: (row) => row.fee ?? '',
      wrap: true
    },
    {
      name: 'FIXED FEE(EUR)',
      selector: (row) => row.fixedFee ?? '',
      wrap: true
    },
    {
      name: 'WITHDRAWAL FEE (%)',
      selector: (row) => row.withdrawFee ?? '',
      wrap: true
    },
    {
      name: 'DEPOSIT FEE',
      selector: (row) => row.depositFee ?? '',
      wrap: true
    },
    {
      name: 'PAYOUT FEE',
      selector: (row) => row.payoutFee ?? '',
      wrap: true
    },
    {
      name: 'z',
      cell: (row: MerchantType) => {
        return <Button onClick={() => handleOpenMerchantDetail(row)}>{'View'}</Button>;
      },

      width: '100px'
    },
    {
      name: '',
      cell: (row: MerchantType) => {
        return <Button onClick={() => handleOpenSubMerchantModal(row)}>{'Add submerchant'}</Button>;
      },

      width: '100px'
    }
  ];
  return (
    <>
      <SubMerchantAddModal
        visible={addSubMerchantVisible}
        handleClose={handleSubCloseModal}
        onSuccess={handleAddSubMerchantSuccess}
        merchantId={merchantId}
      ></SubMerchantAddModal>

      <MerchantAddModal
        visible={addMerchantVisible}
        handleClose={handleCloseModal}
        onSuccess={handleAddMerchantSuccess}
      ></MerchantAddModal>

      <MerchantApproveModal
        merchant={selectedPendingMerchant}
        visible={!!selectedPendingMerchant}
        handleClose={handleCloseApproveModal}
        onSuccess={handleApproveMerchantSuccess}
      ></MerchantApproveModal>

      <Row>
        <Col>
          <Button onClick={handleAddMerchant}>Add Merchant</Button>
        </Col>
      </Row>
      <br />
      <h3>Merchants</h3>
      <DataTable
        responsive={false}
        highlightOnHover={true}
        columns={columns}
        pagination
        data={merchants ?? []}
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'lightgray',
              textTransform: 'uppercase'
            }
          }
        }}
        className="shadow p-3 mb-5 bg-white overflow-auto"
      />
    </>
  );
};
export default Clients;
