import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { clsx } from 'clsx';

import { EnvelopeWhite, LogoFooter } from '../../../assets/icons';
import Icon from '../../Icon';
import { merchantportalHost, PageUrl, githash } from '../../../configuration';

import style from './style.module.scss';

const Footer = (): React.JSX.Element => (
  <>
    <footer className={clsx(style.footer)}>
      <Container fluid className={style.footer_container}>
        <Row>
          <Col sm={12} md={4}>
            <Row>
              <Col className="d-flex justify-content-start">
                <Icon icon={LogoFooter} className={style.logo} />
              </Col>
            </Row>
            <Row>
              <Col className={clsx(style.description, 'd-flex', 'mt-1', 'justify-content-start')}>
                Settlement has never been easier
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4}>
            <Row sm={12}>
              <Col>
                <Link to={`${merchantportalHost}/${PageUrl.TERMS_OF_USE}`} className={style.email}>
                  Terms Of Use
                </Link>
              </Col>
              <Col>
                <Link to={`${merchantportalHost}/${PageUrl.REGULATION}`} className={style.email}>
                  Regulation
                </Link>
              </Col>
              <Col>
                <Link to={`${merchantportalHost}/${PageUrl.PRIVACY_POLICY}`} className={style.email}>
                  Privacy Policy
                </Link>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4}>
            <Container className={style.contacts_container}>
              <Row>
                <Col className={clsx(style.contacts, 'd-flex', 'mb-2', 'justify-content-start')}>Contacts</Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-start">
                  <Button variant="link" href="mailto:hello@infinipi.io" className={style.email}>
                    <Icon icon={EnvelopeWhite} className={style.email} />
                    hello@infinipi.io
                  </Button>
                </Col>
              </Row>
              <Row>
                {/*
                <Col className="d-flex justify-content-start">
                  <Link to={twitterLink}>
                    <Icon icon={TwitterWhite} />
                  </Link>
                  <Link to={telegramLink}>
                    <Icon icon={TelegramWhite} />
                  </Link>
                  <Link to={githubLink}>
                    <Icon icon={GithubWhite} />
                  </Link>
                </Col>
                  */}
              </Row>
            </Container>
          </Col>
          <Col>
            <Row>
              <Col
                className={clsx(
                  style.copyright,
                  'd-flex',
                  'justify-content-md-start',
                  'justify-content-center',
                  'mt-md-0',
                  'mt-4'
                )}
              >
                © 2021 Infinipi
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-md-start justify-content-center">{githash}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  </>
);

export default Footer;
