import React from 'react';

import style from './style.module.scss';

interface props {
  children?: React.ReactNode;
}
const RoundedText = ({ children }: props): React.JSX.Element => {
  return (
    <>
      <div className={style.rounded}>{children}</div>
    </>
  );
};

export default RoundedText;
