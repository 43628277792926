import { createBrowserRouter } from 'react-router-dom';
import React from 'react';

import Regulation from 'pages/User/Regulation';
import TermsOfUse from 'pages/User/TermsOfUse';
import PrivacyPolicy from 'pages/User/PrivacyPolicy';
import CreateClientPayout from 'pages/Admin/CreateClientPayout';
import CreateCounterparty from 'pages/Admin/CreateCounterparty';
import Deposit from 'pages/Admin/Deposit';
import Login from 'shared-components/components/Modals/Login';
import Customers from 'pages/Admin/Customers';
import CompliancePage from 'pages/Admin/Compliance';
import Withdrawals from 'pages/Admin/Withdrawals';
import UnhandledWithdrawals from 'pages/Admin/Withdrawals/unhandledWithdrawals';
import Batches from 'pages/Admin/Withdrawals/batches';

import Profile from './pages/Admin/Profile';
import ApiKeys from './pages/Admin/ApiKeys';
import Landing from './pages/User/Landing';
import Register from './pages/User/Register';
import Clients from './pages/Admin/Clients';
import Pending from './pages/Admin/Pending';
import Dashboard from './pages/Admin/Dashboard';
import Layout from './components/Layout';
import MerchantTransactions from './pages/Admin/MerchantTransactions';
import ContactSales from './pages/User/ContactSales';
import Rfq from './pages/Admin/Rfq';
import AdminLanding from './pages/Admin/AdminLanding';
import ApiKeysAdd from './pages/Admin/ApiKeysAdd';
import {
  AdminPrefix,
  AdminUrl,
  MerchantPrefix,
  PageUrl,
  RootPrefix,
  environment
} from './shared-components/configuration';
import ClientsAdd from './pages/Admin/ClientsAdd';
import MerchantTransactionDetails from './pages/Admin/MerchantTransactionsDetails/merchantTransactionDetails';
import PageLayout from './shared-components/components/PageLayout';
import ClientDetails from './pages/Admin/ClientDetails';

const adminRoutes =
  environment === 'adminprod' ||
  environment === 'admindev' ||
  environment === 'adminstaging' ||
  environment === 'adminuat'
    ? {
        path: AdminPrefix,
        Component: Layout,
        children: [
          {
            path: AdminUrl.MAIN,
            element: <AdminLanding />
          },

          {
            path: AdminUrl.DASHBOARD,
            element: <Dashboard />
          },
          {
            path: AdminUrl.RFQ_LIST,
            element: <Rfq />
          },
          {
            path: `${AdminUrl.RFQ_LIST}/:pid`,
            element: <Rfq />
          },
          {
            path: AdminUrl.MERCHANT_TRANSACTIONS,
            element: <MerchantTransactions />
          },
          {
            path: `${AdminUrl.MERCHANT_TRANSACTIONS}/:merchantId`,
            element: <MerchantTransactionDetails />
          },
          {
            path: AdminUrl.MERCHANTS,
            element: <Clients />
          },
          {
            path: AdminUrl.CUSTOMERS,
            element: <Customers />
          },
          {
            path: `${AdminUrl.MERCHANTS}/:merchantId`,
            element: <ClientDetails />
          },
          {
            path: AdminUrl.MERCHANTS_ADD,
            element: <ClientsAdd />
          },
          {
            path: `${AdminUrl.MERCHANTS_PENDING}/:merchantId`,
            element: <Pending />
          },
          {
            path: `${AdminUrl.MERCHANTS_COUNTERPARTY}/:merchantId`,
            element: <CreateCounterparty />
          },
          {
            path: AdminUrl.COMPLIANCE,
            element: <CompliancePage />
          },
          /*
    {
      path: AdminUrl.TRANSACTION_HISTORY,
      element: <TransactionHistory />,
    },
*/
          {
            path: AdminUrl.PROFILE,
            element: <Profile />
          },
          {
            path: AdminUrl.API_KEYS,
            element: <ApiKeys />
          },
          {
            path: AdminUrl.API_KEYS_ADD,
            element: <ApiKeysAdd />
          },
          {
            path: AdminUrl.CLIENT_PAYOUT,
            element: <CreateClientPayout />
          }
        ]
      }
    : {};

const router = createBrowserRouter([
  {
    path: RootPrefix,
    Component: PageLayout,
    children: [
      {
        path: PageUrl.LANDING,
        element: <Landing />
      },
      {
        path: PageUrl.CONTACT_SALES,
        element: <ContactSales />
      },
      {
        path: PageUrl.REGISTER,
        element: <Register />
      },
      {
        path: PageUrl.LOGIN,
        element: <Login />
      },
      {
        path: PageUrl.PRIVACY_POLICY,
        element: <PrivacyPolicy />
      },
      {
        path: PageUrl.TERMS_OF_USE,
        element: <TermsOfUse />
      },
      {
        path: PageUrl.REGULATION,
        element: <Regulation />
      },
      {
        path: '/test',
        element: <CreateClientPayout />
      }
    ]
  },
  adminRoutes,
  {
    path: MerchantPrefix,
    Component: Layout,
    children: [
      {
        path: AdminUrl.MAIN,
        element: <AdminLanding />
      },

      {
        path: AdminUrl.DASHBOARD,
        element: <Dashboard />
      },
      {
        path: AdminUrl.WITHDRAWALS,
        element: <Withdrawals />
      },
      {
        path: AdminUrl.UNHANDLED_WITHDRAWALS,
        element: <UnhandledWithdrawals />
      },
      {
        path: AdminUrl.BATCHES,
        element: <Batches />
      },
      {
        path: AdminUrl.RFQ_LIST,
        element: <Rfq />
      },
      {
        path: `${AdminUrl.RFQ_LIST}/:pid`,
        element: <Rfq />
      },
      {
        path: AdminUrl.MERCHANT_TRANSACTIONS,
        element: <MerchantTransactions />
      },
      {
        path: AdminUrl.MERCHANTS,
        element: <Clients />
      },
      {
        path: AdminUrl.CUSTOMERS,
        element: <Customers />
      },

      {
        path: AdminUrl.MERCHANTS_ADD,
        element: <ClientsAdd />
      },
      {
        path: 'clients/pending/:merchantId',
        element: <Pending />
      },
      /*
      {
        path: AdminUrl.TRANSACTION_HISTORY,
        element: <TransactionHistory />,
      },
*/
      {
        path: AdminUrl.PROFILE,
        element: <Profile />
      },
      {
        path: AdminUrl.CLIENT_PAYOUT,
        element: <CreateClientPayout />
      },
      {
        path: AdminUrl.API_KEYS,
        element: <ApiKeys />
      },
      {
        path: AdminUrl.API_KEYS_ADD,
        element: <ApiKeysAdd />
      },
      {
        path: AdminUrl.DEPOSIT,
        element: <Deposit />
      }
    ]
  }
]);

export default router;
