import React, { useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';

//import { useApp } from 'shared-components/providers/AppProvider';

const MerchantProfileUser = () => {
  //  const appContext = useApp();
  const [iban, setIban] = useState(/*appContext.userData.iban*/);
  const [email, setEmail] = useState(/*appContext.userData.email*/);
  const [fee, setFee] = useState(0);

  const onEmailChanged = useCallback((event: any) => {
    setEmail(event.target.value);
  }, []);

  const onIbanChanged = useCallback((event: any) => {
    setIban(event.target.value);
  }, []);

  const onFeeChanged = useCallback((event: any) => {
    setFee(event.target.value);
  }, []);

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="loginForm.EmailInput">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            type="email"
            value={email}
            placeholder="name@example.com"
            onChange={onEmailChanged}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginForm.IbanInput">
          <Form.Label>IBAN</Form.Label>
          <Form.Control name="iban" type="text" value={iban} placeholder="" onChange={onIbanChanged} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginForm.FeeInput">
          <Form.Label>Fee</Form.Label>
          <Form.Control name="fee" type="number" value={fee} placeholder="" onChange={onFeeChanged} />
        </Form.Group>
      </Form>
    </>
  );
};

export default MerchantProfileUser;
