import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm, Controller, FormProvider } from 'react-hook-form';

import countries from 'i18n-iso-countries';
import clsx from 'clsx';
import en from 'i18n-iso-countries/langs/en.json';

import { ADD_COUNTERPARTY, CREATE_CLIENT_PAYOUT, CREATE_COUNTERPARTY } from 'shared-components/queries/mutations';
import { Method, Service, environment } from 'shared-components/configuration';
import MakePaymentResultModal from 'components/Modals/MakePaymentResultModal';
import Button from 'shared-components/components/Button';
import { EUR } from 'shared-components/assets/icons';
import Icon from 'components/Icon';
import { LIST_COUNTERPARTY } from 'shared-components/queries/graphql';
import useSWQuery from 'shared-components/hooks/useSWQuery';

import style from './style.module.scss';
import { InputItem } from './inputItem';
import useDebounce from './useDebouce';

type CreateClientPayoutProps = {
  merchantId?: string;
  onSuccess?: (counterpartyId: string) => void;
};

const PaymentForTypes = {
  personal: 'personal',
  corporate: 'corporate'
};

const InitPaymentStatus = { isOpen: false, isError: true, message: '' };
const InitPaymentForm = {
  amount: '',
  paymentType: '',
  iban: '',
  name: '',
  bic: '',
  city: '',
  paymentReference: '',
  addressLine1: '',
  addressLine2: '',
  recipientBankCountry: '',
  postalCode: '',
  recipientCountry: '',
  personalNumber: '',
  phone: '',
  email: ''
};

const PaymentForm = ({ merchantId, onSuccess }: CreateClientPayoutProps) => {
  const [makePaymentStatusModal, setMakePaymentStatusModal] = useState(InitPaymentStatus);
  const [paymentFor, setPaymentFor] = useState(PaymentForTypes.personal);
  const [busy, setBusy] = useState(false);
  const [isLoadingIban, setIsLoadingIban] = useState(false);

  const [counterpartyList, setCounterpartyList] = useState<any>([]);

  const counterpartyListRef = useRef<HTMLSelectElement>(null);

  const buttonText = useMemo(() => {
    if (busy) {
      return <Spinner />;
    }
    return 'Make Payment';
  }, [busy]);

  const {
    control,
    handleSubmit: handleSubmitForm,
    getValues,
    reset,
    watch,
    setValue,
    ...restFormMethod
  } = useForm<any>({
    defaultValues: InitPaymentForm
  });

  const countryList = useMemo(() => {
    countries.registerLocale(en);
    return countries.getNames('en', { select: 'official' });
  }, []);

  const listCounterpartyQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'listCounterparty',
    data: {
      query: LIST_COUNTERPARTY
    },
    auto: false,
    onResponse: (data: any) => {
      setCounterpartyList(data);
    }
  });

  const addCounterpartyQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'addCounterparty',
    data: {
      query: ADD_COUNTERPARTY
    },
    auto: false,
    onResponse: (_data: any) => {
      //console.log(data);
      listCounterpartyQuery.execute();
    }
  });

  const createCounterpartyQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'createCounterparty',
    data: {
      query: CREATE_COUNTERPARTY
    },
    auto: false,
    onResponse: (data: any) => {
      onSuccess?.(data);
      reset(InitPaymentForm);
      setBusy(false);
      if (!onSuccess) {
        setMakePaymentStatusModal({
          isOpen: true,
          isError: false,
          message: `Counterparty created (${data.counterpartyId})`
        });
      }
      listCounterpartyQuery.execute();
    }
  });

  const createClientPayoutQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'createClientPayout',
    data: {
      query: CREATE_CLIENT_PAYOUT
    },
    auto: false,
    onResponse: (data: any) => {
      setBusy(false);
      setMakePaymentStatusModal({
        isOpen: true,
        isError: false,
        message: `Pay-out sent`
      });
      reset(InitPaymentForm);
      addCounterpartyQuery.execute({
        variables: {
          input: data
        }
      });
    }
  });

  useEffect(() => {
    listCounterpartyQuery.execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  TODO: 
  place that somewhere
            if (event.data.data?.errors) {
            setBusy(false);
            setIsLoadingIban(false);
          }
          if (event.data.data?.errors && event.data.data?.errors[0]?.message === 'PAYMENT_REQUEST_ERROR') {
            setBusy(false);
            setMakePaymentStatusModal({
              isOpen: true,
              isError: true,
              message: 'An error occurred, please try again later'
            });
          }

  */

  const handleChangeIban = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const iban = event.currentTarget.value;

      const countryCode = iban.slice(0, 2).toUpperCase();
      const countryCodeToSet = Object.keys(countryList)?.find((element) => element === countryCode);

      if (countryCodeToSet) {
        setValue('recipientBankCountry', countryCodeToSet);
      }
    },
    [countryList, setValue]
  );

  const handleCounterpartyChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value: any = event.currentTarget.value;
      const found: any = counterpartyList?.find((element: any) => element['iban'] === value);
      if (found == null) {
        return;
      }
      setValue('addressLine1', found?.addressLine1);
      setValue('addressLine2', found?.addressLine2);
      setValue('amount', found?.amount);
      setValue('bic', found?.bic);
      setValue('city', found?.city);
      setValue('currency', found?.currency);
      setValue('iban', found?.iban);
      setValue('name', found?.name);
      setValue('paymentReference', found?.paymentReference);
      setValue('postalCode', found?.postalCode);
      setValue('recipientBankCountry', found?.recipientBankCountry);
      setValue('recipientCountry', found?.recipientCountry);
      setValue('recipientType', found?.recipientType);
    },
    [counterpartyList, setValue]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setBusy(true);
      const isIndividualRecipient = paymentFor === PaymentForTypes.personal;

      const recipientType = isIndividualRecipient ? 'INDIVIDUAL' : 'COMPANY';
      const {
        name,
        iban,
        bic,
        recipientBankCountry,
        amount,
        paymentReference,
        recipientCountry,
        addressLine1,
        addressLine2,
        city,
        postalCode
        // phone,
        // email,
        // personalNumber,
      } = getValues();

      const paymentInfo = {
        name,
        recipientType: recipientType,
        iban,
        bic,
        recipientBankCountry,
        currency: 'EUR',
        // TODO: check environment currency
        // environment !== "adminprod" && environment !== "admindev"
        //   ? currency.value
        //   : "EUR",
        amount: Number.parseFloat(amount),
        paymentReference,
        recipientCountry,
        addressLine1,
        addressLine2,
        city,
        postalCode
        // TODO: update missing fields below
        // personalNumber: isIndividualRecipient ? personalNumber : undefined,
        // phone: isIndividualRecipient ? phone : undefined,
        // email: isIndividualRecipient ? email : undefined,
      };

      if (environment === 'adminprod' || environment === 'admindev' || environment === 'adminstaging') {
        createCounterpartyQuery.execute({
          variables: {
            merchantId: merchantId,
            input: paymentInfo
          }
        });
      } else {
        createClientPayoutQuery.execute({
          variables: {
            input: paymentInfo
          }
        });
      }
    },
    [createClientPayoutQuery, createCounterpartyQuery, getValues, merchantId, paymentFor]
  );

  const searchIban = watch('iban');

  useDebounce(
    () => {
      // Only tested on dev, not yet on admin site
      if (searchIban && environment !== 'adminprod' && environment !== 'admindev') {
        console.log('searchIban', searchIban);
        setIsLoadingIban(true);
        App.sendMessage({
          service: 'graphql',
          data: {
            query: '{}',
            params: {
              variables: { iban: searchIban }
            }
          }
        });
      }
    },
    [searchIban],
    800
  );

  return (
    <>
      <FormProvider
        {...{
          ...restFormMethod,
          setValue,
          watch,
          control,
          getValues,
          reset,
          handleSubmit: handleSubmitForm
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md>
              <InputItem
                name={'amount'}
                label={'Amount'}
                type="number"
                inputStyle={{ paddingRight: 80 }}
                suffixIcon={
                  <>
                    <Icon icon={EUR} style={{ height: 24, paddingRight: 8 }} />
                    EUR
                  </>
                }
              />
              <Form.Floating className={clsx(style.floatingItem, 'mb-3')}>
                <Form.Select
                  onChange={handleCounterpartyChange}
                  aria-label=""
                  ref={counterpartyListRef}
                  className={style.formItem}
                >
                  <option>Select iban from list or enter below</option>
                  {Object.keys(counterpartyList).map((value: any, key) => {
                    return (
                      <option key={key} value={counterpartyList[value]['iban']}>
                        {counterpartyList[value]['iban']}
                      </option>
                    );
                  })}
                </Form.Select>
                <label className={style.label} htmlFor={'recipientBankCountry'}>
                  {'Counterparty list'}
                </label>
              </Form.Floating>
              <InputItem
                name={'iban'}
                label={'IBAN'}
                otherHandleChange={handleChangeIban}
                otherHandleBlur={(e) => {
                  const value = e.target.value?.replace(/\s/g, '');
                  setValue('iban', value);
                }}
                inputStyle={{ paddingRight: isLoadingIban ? 80 : 10 }}
                suffixIcon={isLoadingIban && <Spinner animation="border" variant="primary" />}
              />

              <InputItem name={'bic'} label={'BIC'} isTrimValue />
              <InputItem name={'paymentReference'} label={'Payment reference (optional)'} isTrimValue />
              <Form.Floating className={clsx(style.floatingItem, 'mb-3')}>
                <Controller
                  control={control}
                  name="recipientBankCountry"
                  render={({ field }) => (
                    <Form.Select
                      aria-label=""
                      // ref={recipientBankCountryRef}
                      className={style.formItem}
                      {...field}
                    >
                      <option>Choose recipient bank country</option>
                      {Object.keys(countryList).map((value, key) => (
                        //FAKE DEFAULT VALUE - TODO: REMOVE SOON
                        <option key={key} value={value} selected={value === 'LT'}>
                          {countryList?.[value]}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                <label className={style.label} htmlFor={'recipientBankCountry'}>
                  {'Recipient bank country'}
                </label>
              </Form.Floating>
            </Col>
            <Col md>
              <div className="d-flex mb-3 gap-3">
                <Button
                  variant="outline-primary"
                  onClick={() => setPaymentFor(PaymentForTypes.personal)}
                  className={clsx(
                    style.radioButton,
                    'w-100',
                    paymentFor === PaymentForTypes.personal ? style.radioButtonActive : {}
                  )}
                >
                  <Form.Check
                    inline
                    checked={paymentFor === PaymentForTypes.personal}
                    label="Personal"
                    name={PaymentForTypes.personal}
                    type={'radio'}
                    className="pe-none"
                  />
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => setPaymentFor(PaymentForTypes.corporate)}
                  className={clsx(
                    style.radioButton,
                    'w-100',
                    paymentFor === PaymentForTypes.corporate ? style.radioButtonActive : {}
                  )}
                >
                  <Form.Check
                    inline
                    checked={paymentFor === PaymentForTypes.corporate}
                    label="Corporate"
                    name={PaymentForTypes.corporate}
                    type={'radio'}
                    className="pe-none"
                  />
                </Button>
              </div>

              {paymentFor === PaymentForTypes.personal ? (
                <>
                  <InputItem name={'personalNumber'} label={'Personal number (optional)'} isTrimValue />
                  <InputItem name={'name'} label={'Name and Lastname'} isTrimValue />
                </>
              ) : (
                <InputItem name={'name'} label={'Company name'} isTrimValue />
              )}
              <InputItem name={'addressLine1'} label={'Address line 1'} isTrimValue />
              <InputItem name={'addressLine2'} label={'Address line 2 (optional)'} isTrimValue />
              <InputItem name={'postalCode'} label={'Postal code'} isTrimValue />
              <InputItem name={'city'} label={'City'} isTrimValue />
              <Form.Floating className={clsx(style.floatingItem, 'mb-3')}>
                <Controller
                  control={control}
                  name="recipientCountry"
                  render={({ field }) => (
                    <Form.Select
                      aria-label=""
                      // ref={recipientBankCountryRef}
                      className={style.formItem}
                      {...field}
                    >
                      <option>Choose recipient country</option>
                      {Object.keys(countryList).map((value, key) => (
                        //FAKE DEFAULT VALUE - TODO: REMOVE SOON
                        <option key={key} value={value} selected={value === 'LT'}>
                          {countryList?.[value]}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                <label className={style.label} htmlFor={'recipientCountry'}>
                  {'Recipient country'}
                </label>
              </Form.Floating>
              {paymentFor === PaymentForTypes.personal && (
                <>
                  <InputItem name={'phone'} label={'Phone (optional)'} isTrimValue />
                  <InputItem name={'email'} label={'Email (optional)'} isTrimValue />
                </>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={{ span: 6, offset: 6 }}>
              <Button className="w-100 " type="submit" style={{ height: 52 }} disabled={busy}>
                {buttonText}
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>

      <MakePaymentResultModal
        isOpen={makePaymentStatusModal.isOpen}
        isError={makePaymentStatusModal.isError}
        message={makePaymentStatusModal.message}
        onClose={() => setMakePaymentStatusModal(InitPaymentStatus)}
      />
    </>
  );
};
export default PaymentForm;
