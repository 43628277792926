import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { BaseProps } from 'shared-components/utils/types';

interface props extends BaseProps {
  isOpen: boolean;
  isError: boolean;
  onClose?: () => void;
  message?: string;
}
const MakePaymentResultModal = ({ isOpen, isError, onClose, message }: props): React.JSX.Element => {
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isError ? 'Error' : 'Success'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button className="px-3" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MakePaymentResultModal;
