import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';

import clsx from 'clsx';

import { ADMIN_ADD_MERCHANT_MUTATION } from 'shared-components/queries/graphql';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';

import style from './style.module.scss';

interface Props {
  children?: React.ReactNode;
  visible: boolean;
  handleClose?: () => void;
  onSuccess?: () => void;
}

const MerchantAddModal = ({ handleClose, visible, onSuccess }: Props) => {
  const [alertVisible, setAlertVisible] = useState(false);

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'addMerchant',
    data: {
      query: ADMIN_ADD_MERCHANT_MUTATION
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.success === true) {
        onSuccess?.();
      }
    }
  });

  useEffect(() => {
    if (visible) {
      setAlertVisible(false);
    }
  }, [visible]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setAlertVisible(false);
      const name = event.currentTarget.elements.name.value;
      const email = event.currentTarget.elements.email.value;
      const iban = event.currentTarget.elements.iban.value;
      query.execute({
        variables: {
          name: name,
          email: email,
          iban: iban,
          status: true
        }
      });
    },
    [query]
  );

  return (
    <Modal show={visible} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <span className={style.header}>Add Merchant</span>
        </Modal.Header>
        <Modal.Body>
          <center>
            <FloatingLabel controlId="name" label="Company name" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="text" size="sm" placeholder="Name" />
            </FloatingLabel>

            <FloatingLabel controlId="email" label="Email address" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="email" size="sm" placeholder="" />
            </FloatingLabel>

            <FloatingLabel controlId="iban" label="IBAN" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="text" size="sm" placeholder="iban" />
            </FloatingLabel>
          </center>
          <Alert variant={'danger'} show={alertVisible}>
            Error while adding merchant
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Add merchant</Button>
          <Button type="reset">Reset form</Button>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default MerchantAddModal;
