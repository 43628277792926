import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { BrowserView, MobileView } from 'react-device-detect';

import { useAuthEID } from '../../../providers/AuthEIDProvider';
import { AdminPrefix, MerchantPrefix, PageUrl, environment } from 'shared-components/configuration';
import { useApp } from 'shared-components/providers/AppProvider';
import { useHash } from 'shared-components/hooks/useHash';
import WaitSpinner from 'shared-components/components/WaitSpinner';

import style from './style.module.scss';

interface LoginProps {
  redirectUrl?: string;
}
const Login = ({ redirectUrl }: LoginProps): React.JSX.Element => {
  const prefix = useMemo(() => {
    return environment.startsWith('admin') ? AdminPrefix : MerchantPrefix;
  }, []);

  const {
    values: { status, loggedIn, serviceWorkerReady }
  } = useApp();
  const { selectorInitStop, selectorInitExecute, requestId } = useAuthEID();
  const [show, setShow] = useState(false);
  const location = useLocation();
  const { hash, updateHash } = useHash();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    updateHash('#');
  }, [updateHash]);

  useEffect(() => {
    console.log(status);
  }, [status]);
  useEffect(() => {
    if (loggedIn) {
      setShow(false);
      selectorInitStop();
      updateHash('');
      return;
    }
    if (hash === '#login') {
      setShow(true);
      selectorInitExecute();
    } else {
      setShow(false);
      selectorInitStop();
      updateHash('');
    }
  }, [hash, serviceWorkerReady, status.autheidConnected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (serviceWorkerReady === false) {
      return;
    }
    if (loggedIn === true) {
      setShow(false);
    } else {
      if (location.pathname.includes(prefix)) {
        setShow(true);
      }
    }
  }, [location.pathname, loggedIn, prefix, serviceWorkerReady]);

  useEffect(() => {
    if (loggedIn && location.pathname === `/${PageUrl.LANDING}` && redirectUrl) {
      navigate(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, loggedIn, redirectUrl]);

  return (
    <>
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p className={style.header}>Login with Auth eID App </p>
          <p className={style.subheader}>
            Don&apos;t have the Auth eID App? <br />
            <a target="_blank" href="https://autheid.com/" rel="noreferrer">
              Get started here.
            </a>
          </p>
          <center>
            {requestId.length > 0 ? (
              <>
                <BrowserView>
                  <QRCode value={`autheid://autheid.com/app/requests/?request_id=${requestId}`} />
                </BrowserView>
                <MobileView>
                  <Button size="lg" href={`autheid://autheid.com/app/requests/?request_id=${requestId}`}>
                    Run authEID app
                  </Button>
                </MobileView>
              </>
            ) : (
              <div>
                Connecting to login service. Please wait.
                <br />
                <WaitSpinner />
              </div>
            )}
          </center>
          <div>
            <div className={style.numbers}>
              <ol>
                <li>Open the Auth eID App on your mobile phone.</li>
                <li>Tap the QR symbol on the Auth eID App.</li>
                <li>Point the camera at the QR code in this field.</li>
              </ol>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;
