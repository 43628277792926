export interface BaseProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export enum TransactionCode {
  HEADER = '11',
  FIXED_INFO = '12',
  PAYMENT = '14',
  CREDIT_INVOICE = '15',
  FOOTER = '29',
  ACCOUNT_INFO = '40'
}

export type LbRecord = {
  transactionCode: number;
  receiverNumber: number;
  ocrReferens: string;
  amount: number;
  paymentDate: string;
  infoToSender: string;
};
