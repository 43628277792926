import React from 'react';
import { Col, Row } from 'react-bootstrap';

import styles from './style.module.scss';

export const RowItem = (props: { label: string; value: string }) => {
  const { label, value } = props;
  return (
    <div className={styles.item}>
      <Row>
        <Col sm={5}>
          <span className={styles.label}>{label}</span>
        </Col>
        <Col sm={7}>
          <span className={styles.value}>{value}</span>
        </Col>
      </Row>
    </div>
  );
};
