import Sidebar from 'react-bootstrap-sidebar-menu';
import React from 'react';

import { LogoFCSvg } from '../../../shared-components/assets/icons';
import Icon from '../../Icon';
import ContactBox from '../../ContactBox';
import { useApp } from 'shared-components/providers/AppProvider';

import NavMenu from './navmenu';

const SideBar = () => {
  const {
    values: { loggedIn }
  } = useApp();

  return (
    <>
      <Sidebar /* variant={appContext.theme} */ expand="md" className="d-none d-md-block">
        <Sidebar.Collapse getScrollValue={300}>
          <Sidebar.Header style={{ height: 58, paddingLeft: '1.5rem' }}>
            <Sidebar.Brand className="d-none d-md-block">
              <Icon
                icon={LogoFCSvg}
                style={{ marginLeft: '30px', maxWidth: '250px', maxHeight: '50px', alignSelf: 'center' }}
              />
            </Sidebar.Brand>
          </Sidebar.Header>
          <Sidebar.Body>{loggedIn && <NavMenu />}</Sidebar.Body>
          <Sidebar.Footer className="d-none d-md-block">
            <ContactBox />
          </Sidebar.Footer>
        </Sidebar.Collapse>
      </Sidebar>
    </>
  );
};
export default SideBar;
