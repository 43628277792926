import React from 'react';
import { RouterProvider } from 'react-router-dom';

import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'shared-components/styles/index.scss';
import 'shared-components/styles/custom.scss';
import 'index.scss';
import 'custom.scss';
import * as serviceWorker from 'shared-components/serviceWorker';
import AppProvider from 'shared-components/providers/AppProvider';
//import { MerchantInfoProvider } from 'shared-components/providers/MerchantInfoProvider';
import AuthEIDProvider from 'shared-components/providers/AuthEIDProvider';
import GraphQLProvider from 'shared-components/providers/GraphQLProvider';
import { MerchantInfoProvider } from 'shared-components/providers/MerchantInfoProvider';
import Layout from 'components/Layout';
import { MerchantsProvider } from 'contexts/GraphQL';
import { BalancesProvider } from 'contexts/balances';

import router from './router';

declare global {
  interface Window {
    App: any;
    console: any;
  }
}

declare global {
  interface Window {
    App: any;
    console: any;
  }
  let App: any;
}
window.App = window.App || ({} as { channel: BroadcastChannel });

App.logData = (data: object) => {
  console.log(`LOGGER: ${JSON.stringify(data)}`);
};
/*
window.addEventListener('error', function (error) {
  console.error(error);
  App.logData(error.message);
  error.preventDefault();
});
*/

App.channel = new BroadcastChannel('merchant-sw');

App.logger = new BroadcastChannel('logger');

App.logger.addEventListener('message', (event: MessageEvent<any>) => {
  const data = JSON.stringify(event.data);
  console.log(data);
});

App.sendMessage = (message: string) => {
  return new Promise(function (resolve, reject) {
    const messageChannel = new MessageChannel();
    messageChannel.port1.addEventListener('message', function (event) {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    });
    navigator.serviceWorker?.controller?.postMessage(message, [messageChannel.port2]);
  });
};
serviceWorker.register();

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);

root.render(
  <AppProvider>
    <AuthEIDProvider>
      <GraphQLProvider>
        <MerchantInfoProvider>
          <MerchantsProvider>
            <BalancesProvider>
              <RouterProvider router={router} future={{ v7_startTransition: true }} fallbackElement={<Layout />} />
            </BalancesProvider>
          </MerchantsProvider>
        </MerchantInfoProvider>
      </GraphQLProvider>
    </AuthEIDProvider>
  </AppProvider>
);
