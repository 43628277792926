import React, { useCallback, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { DtPicker } from 'react-calendar-datetime-picker';
import DataTable from 'react-data-table-component';

import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import { CREATE_BATCH, UPDATE_WITHDRAWAL_STATUS } from 'shared-components/queries/mutations';
import { PAYOUTS_QUERY } from 'shared-components/queries/graphql';
import { useSWCallbackWithdrawalEvent } from 'shared-components/providers/SWEventProvider';

import style from './style.module.scss';

const UnhandledWithdrawals = () => {
  const date = new Date();
  const yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  const [dateRange, setDateRange] = useState<any>({});
  const [buttonLock, setButtonLock] = useState<boolean>(false);
  const [lbData, setLbData] = useState<any>([]);

  const payoutsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'payouts',
    data: {
      query: PAYOUTS_QUERY
    },
    auto: true,
    onResponse: (data: any) => {
      const payoutsData = data?.edges?.map((value: any) => {
        const values = value.node;
        return values;
      });

      setLbData(payoutsData);
    }
  });

  const updateWithdrawalStatusQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateWithdrawalStatus',
    data: {
      query: UPDATE_WITHDRAWAL_STATUS
    },
    auto: false,
    onResponse: (_data: any) => {
      //payoutsQuery.execute();
    }
  });

  const createBatchQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'createBatch',
    data: {
      query: CREATE_BATCH
    },
    auto: false,
    onResponse: (_data: any) => {
      payoutsQuery.execute();
      setButtonLock(false);
    }
  });

  const handleBatchCreate = useCallback(() => {
    setButtonLock(true);
    const yearFrom = dateRange.from.year.toString();
    const yearTo = dateRange.to.year.toString();
    const monthFrom = dateRange.from.month.toString().padStart(2, '0');
    const monthTo = dateRange.to.month.toString().padStart(2, '0');
    const dayFrom = dateRange.from.day.toString().padStart(2, '0');
    const dayTo = dateRange.to.day.toString().padStart(2, '0');
    const dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}T00:00:00Z`;
    const dateTo = `${yearTo}-${monthTo}-${dayTo}T23:59:59Z`;
    createBatchQuery.execute({
      variables: {
        startTime: dateFrom,
        endTime: dateTo
      }
    });
  }, [createBatchQuery, dateRange]);

  useSWCallbackWithdrawalEvent((_data: any) => {
    payoutsQuery.execute();
  });

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Button onClick={handleBatchCreate} disabled={buttonLock}>
          Create Batch
        </Button>
        <div style={{ margin: '10px', width: '300px', alignSelf: 'center' }}>
          <DtPicker
            type="range"
            placeholder="select date"
            showWeekend
            todayBtn
            initValue={{
              from: {
                year: yesterday.getFullYear(),
                month: yesterday.getMonth() + 1,
                day: yesterday.getDate()
              },
              to: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
            }}
            onChange={(date: any) => {
              setDateRange(date);
            }}
          />
        </div>
      </div>
      <Card className={style.card}>
        <Card.Title>
          <div style={{ display: 'flex' }}>Unhandled withdrawals</div>
        </Card.Title>
        <Card.Text>
          <DataTable
            columns={[
              {
                name: 'Created at',
                selector: (row: any) => `${row?.['createdAt'].replace('T', ' ')}`
              },
              {
                name: 'Payout reference',
                selector: (row: any) => row?.['paymentReference']
              },
              {
                name: 'IBAN',
                selector: (row: any) => row?.['clientIban']
              },
              {
                name: 'Amount',
                selector: (row: any) => `${row?.['amount'].toFixed(2)} ${row?.['currency'].toUpperCase()}`
              },
              {
                name: 'Status',
                selector: (row: any) => row?.['status']
              },
              {
                name: 'cancel',
                cell: (row: any) => (
                  <>
                    <Button
                      style={{ visibility: row.status === 'created' ? 'visible' : 'hidden' }}
                      onClick={() => {
                        updateWithdrawalStatusQuery.execute({
                          variables: {
                            input: {
                              withdrawalId: atob(row.id).split(':').at(1),
                              newStatus: 'rejected'
                            }
                          }
                        });
                      }}
                    >
                      Cancel withdrawal
                    </Button>
                  </>
                )
              }
            ]}
            data={lbData}
            pagination
          />
        </Card.Text>
      </Card>
    </>
  );
};

export default UnhandledWithdrawals;

/*

                    <Button
                      onClick={() => {
                        const test1 = parseIban('SE8030000000041351300039');
                        const test2 = parseIban('SE5860000000000517182351');
                        const test3 = parseIban('SE5580000832790147258925');
                        console.log(test1);
                        console.log(test2);
                        console.log(test3);
                      }}
                    >
                      TEST
                    </Button>
*/
