import { Button, Card, FloatingLabel, Form } from 'react-bootstrap';
import React, { FormEvent, useCallback, useState } from 'react';

import { useMerchantInfo } from '../../../shared-components/providers/MerchantInfoProvider';
import { UPDATE_MERCHANT_IBAN, UPDATE_WEBHOOK } from 'shared-components/queries/mutations';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import InfoModal from 'components/Modals/Info';
import useApiKeys from 'shared-components/hooks/useApiKeys';

import { RowItem } from './rowItem';

const Profile = () => {
  const keys = useApiKeys();
  const merchantInfo = useMerchantInfo();
  const [success, setSuccess] = useState<boolean>(false);
  const [profileSuccess, setProfileSuccess] = useState<boolean>(false);
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const [profileDialogShow, setProfileDialogShow] = useState<boolean>(false);

  const webhookUpdate = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateWebhook',
    data: {
      query: UPDATE_WEBHOOK
    },
    auto: false,
    onResponse: (data: any) => {
      setSuccess(data.success);
      setDialogShow(true);
    }
  });

  const profileUpdate = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateMerchant',
    data: {
      query: UPDATE_MERCHANT_IBAN
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.success === true) {
        setProfileSuccess(data.success);
        setProfileDialogShow(true);
        merchantInfo.refresh();
      }
    }
  });

  const handleProfileUpdate = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formElements = event.currentTarget.elements;
      const iban = formElements.namedItem('iban') as HTMLInputElement;
      profileUpdate.execute({
        variables: {
          newIban: iban.value,
          email: merchantInfo.email
        }
      });
    },
    [merchantInfo.email, profileUpdate]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formElements = event.currentTarget.elements;
      const publicKey = formElements.namedItem('publicKey') as HTMLInputElement;
      const webhookUrl = formElements.namedItem('webhook') as HTMLInputElement;
      webhookUpdate.execute({
        variables: {
          webhook: webhookUrl.value,
          publicKey: publicKey.value
        }
      });
    },
    [webhookUpdate]
  );
  return (
    <>
      <InfoModal
        title={'Profile update'}
        visible={profileDialogShow}
        onOk={() => {
          setProfileDialogShow(false);
        }}
        handleClose={() => {
          setProfileDialogShow(false);
        }}
      >
        {profileSuccess ? 'Profile updated successfully' : 'Webhook creation fail'}
      </InfoModal>

      <InfoModal
        title={'Webhook info'}
        visible={dialogShow}
        onOk={() => {
          setDialogShow(false);
        }}
        handleClose={() => {
          setDialogShow(false);
        }}
      >
        {success ? 'Webhook added successfully' : 'Webhook creation fail'}
      </InfoModal>
      <Form onSubmit={handleProfileUpdate}>
        <Card className="rounded-1 pt-3 pb-2 px-2 border-0 shadow-sm">
          <RowItem label="Merchant ID" value={merchantInfo.id} />
          <RowItem label="Company Name" value={merchantInfo.name} />
          <RowItem label="Email" value={merchantInfo.email} />
          <FloatingLabel controlId="iban" label="Set IBAN number" className="mb-2">
            <Form.Control type="text" size="sm" placeholder="IBAN number" defaultValue={merchantInfo.iban} />
          </FloatingLabel>

          <RowItem label="Counterparty ID" value={merchantInfo.counterpartyId} />
          <RowItem label="Payout Fee" value={merchantInfo.payoutFee.toString()} />
          <RowItem label="Deposit Fee" value={merchantInfo.depositFee.toString() || 'N/A'} />
          <RowItem label="Withdrawal Fee" value={merchantInfo.withdrawFee.toString() || 'N/A'} />
          <center>
            <Button type="submit">Update profile data</Button>
          </center>
        </Card>
      </Form>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Form.Select aria-label="Key" name="publicKey">
            {keys?.keys.map((element: any, key: any) => <option key={key}>{element.key}</option>)}
          </Form.Select>
          {/*
          <FloatingLabel controlId="publicKey" label="Public Key" className="mb-2">
            <Form.Control as="textarea" type="text" size="sm" placeholder="Public key" style={{ height: '200px' }} />
          </FloatingLabel>
          */}
          <FloatingLabel controlId="webhook" label="Webhook url" className="mb-2">
            <Form.Control type="text" size="sm" placeholder="http://someurl" />
          </FloatingLabel>
          <center>
            <Button type="submit">Update webhook</Button>
          </center>
        </Form>
      </Card>
    </>
  );
};
export default Profile;
