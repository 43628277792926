import React, { CSSProperties, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import style from './style.module.scss';

export const InputItem = ({
  name,
  label,
  type,
  className,
  inputClassName,
  inputStyle,
  suffixIcon,
  isTrimValue,
  otherHandleChange,
  otherHandleBlur
}: {
  name: string;
  label: string;
  type?: string;
  className?: string;
  inputClassName?: string;
  inputStyle?: CSSProperties;
  suffixIcon?: ReactNode;
  isTrimValue?: boolean;
  otherHandleChange?: (e: any) => void;
  otherHandleBlur?: (e: any) => void;
}) => {
  const { control, setValue } = useFormContext();
  return (
    <Form.Floating className={clsx(style.floatingItem, 'mb-3', className)}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Form.Control
            type={type || 'text'}
            placeholder=""
            className={clsx(style.formItem, inputClassName)}
            {...field}
            style={inputStyle}
            onChange={(e) => {
              field.onChange(e);
              otherHandleChange && otherHandleChange(e);
            }}
            onBlur={(e) => {
              field.onBlur();

              if (isTrimValue) {
                const value = (e.target.value || '').trim();

                setValue(name, value);
              }
              otherHandleBlur && otherHandleBlur(e);
            }}
          />
        )}
      />
      {suffixIcon && (
        <div className="position-absolute end-0 top-0 h-100 mx-3  d-flex justify-content-center align-items-center">
          {suffixIcon}
        </div>
      )}
      <label className={style.label} htmlFor={name}>
        {label}
      </label>
    </Form.Floating>
  );
};
