import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import Icon from '../../Icon';
import { Ok } from '../../../shared-components/assets/icons';

import style from './style.module.scss';

interface RegisterProps {
  children?: React.ReactNode;
  visible: boolean;
  handleClose?: () => void;
}

const PublicKeyCompleteModal = ({ children, visible, handleClose }: RegisterProps): React.JSX.Element => {
  return (
    <>
      <Modal show={visible} onHide={handleClose} className={style.modal_container}>
        <Modal.Header closeButton className={style.modal_header}>
          <Modal.Title className="w-100">
            <center>
              <Icon icon={Ok} />
            </center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <h1 className={style.h1}>Thank you</h1>
            <p></p>
            <h2 className={style.h2}>Your key has been added to the system</h2>
            {children}
          </center>
        </Modal.Body>
        <Modal.Footer className={style.modal_footer}>
          <Button variant="primary" size="lg" className={style.button_ok} onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublicKeyCompleteModal;
