import React, { useEffect, useMemo } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';

import { AdminPrefix, AdminUrl, MerchantPrefix, environment } from 'shared-components/configuration';
import { useApp } from 'shared-components/providers/AppProvider';

const AdminLanding = () => {
  const prefix = useMemo(() => {
    return environment.startsWith('admin') ? AdminPrefix : MerchantPrefix;
  }, []);

  const {
    values: { loggedIn }
  } = useApp();

  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      return;
    }
    navigate(`${prefix}/${AdminUrl.DASHBOARD}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);
  return (
    <>
      <ReactLoading />
    </>
  );
};

export default AdminLanding;
