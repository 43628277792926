import React, { useState } from 'react';
import { Container, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import clsx from 'clsx';

import { PageContent } from 'shared-components/components/PageLayout/PageContent';

import style from './style.module.scss';
import PaymentForm from './paymentForm';
import { BatchFrom } from './batchForm';

type CreateClientPayoutProps = {
  merchantId?: string;
  onSuccess?: (counterpartyId: string) => void;
};

const ToggleSide = {
  manual: 1,
  batch: 2
};

const CreateClientPayout = ({ merchantId }: CreateClientPayoutProps) => {
  const [toggleValue, setToggleValue] = useState(ToggleSide.manual);

  return (
    <PageContent
      title="Payout"
      classContainer={style.container}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 790,
        minHeight: '100%'
      }}
    >
      <Container fluid="md" className="p-0">
        <ToggleButtonGroup
          type="radio"
          name="options"
          defaultValue={1}
          onChange={(value) => setToggleValue(value)}
          className="w-100 mb-5"
          style={{ border: '1px solid #a9a9a9' }}
        >
          <ToggleButton
            id="tbg-check-1"
            value={ToggleSide.manual}
            className={clsx(style.toggleButton, toggleValue === ToggleSide.manual && style.toggleButtonActive)}
            variant=""
          >
            Manual
          </ToggleButton>
          <ToggleButton
            variant=""
            id="tbg-check-2"
            value={ToggleSide.batch}
            className={clsx(style.toggleButton, toggleValue === ToggleSide.batch && style.toggleButtonActive)}
          >
            Batch (CSV file)
          </ToggleButton>
        </ToggleButtonGroup>
        {toggleValue === ToggleSide.manual ? <PaymentForm merchantId={merchantId} /> : <BatchFrom />}
      </Container>
    </PageContent>
  );
};
export default CreateClientPayout;
