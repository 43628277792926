import React from 'react';

import { useAuthEID } from 'shared-components/providers/AuthEIDProvider';

import MerchantProfileAdmin from './admin';
import MerchantProfileUser from './merchant';

const MerchantProfile = ({ data }: { data: any }) => {
  const { isAdmin } = useAuthEID();
  return isAdmin ? <MerchantProfileAdmin data={data} /> : <MerchantProfileUser />;
};
export default MerchantProfile;
