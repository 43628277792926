import React, { useState } from 'react';
import { Container, Navbar, Offcanvas } from 'react-bootstrap';
import Sidebar from 'react-bootstrap-sidebar-menu';

import ContactBox from '../../ContactBox';
import Icon from '../../Icon';
import { LogoFCSvg } from '../../../shared-components/assets/icons';
import { useApp } from 'shared-components/providers/AppProvider';

import NavMenu from './navmenu';

export const CanvasMenu = () => {
  const {
    values: { loggedIn }
  } = useApp();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClickLink = () => {
    handleClose();
  };

  return (
    <Navbar expand="md" className="d-block d-md-none py-0">
      <Container className="p-0">
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="shadow-none border-0" onClick={handleShow} />
        <Offcanvas
          id={`offcanvasNavbar`}
          aria-labelledby={`offcanvasNavbar`}
          placement="start"
          style={{ maxWidth: 300 }}
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton className="justify-content-between">
            <Navbar.Brand className="" href="/">
              <Icon icon={LogoFCSvg} />
            </Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body className="justify-content-end overflow-hidden pt-0">
            <Sidebar /*variant={appContext.theme} */ style={{ height: '100%' }} expand="xs">
              <Sidebar.Body className="p-0 w-100">{loggedIn && <NavMenu onClickItem={onClickLink} />}</Sidebar.Body>
              <Sidebar.Footer>
                <ContactBox />
              </Sidebar.Footer>
            </Sidebar>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </Navbar>
  );
};
