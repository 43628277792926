import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Sidebar from 'react-bootstrap-sidebar-menu';

import Icon from 'components/Icon';
import { environment } from 'shared-components/configuration';

export enum NavlinkVisibleEnum {
  MERCHANT,
  ADMIN,
  BOTH
}
type NavLinkAdminProps = {
  visible: NavlinkVisibleEnum;
  url?: string;
  onClick: () => void;
  icon: string;
  title: string;
  newTab?: boolean;
};
export const NavLinkAdmin = ({ url, onClick, icon, title, visible, newTab }: NavLinkAdminProps) => {
  const isVisible = useMemo(() => {
    if (visible === NavlinkVisibleEnum.BOTH) {
      return true;
    }
    if (visible === NavlinkVisibleEnum.ADMIN && environment.startsWith('admin')) {
      return true;
    }
    if (visible === NavlinkVisibleEnum.MERCHANT && !environment.startsWith('admin')) {
      return true;
    }
    return false;
  }, [visible]);

  return isVisible ? (
    <Sidebar.Nav.Link
      as={url === undefined ? undefined : NavLink}
      target={newTab ? '_blank' : undefined}
      to={url === undefined ? '#' : url}
      onClick={onClick}
    >
      <Sidebar.Nav.Icon>
        <Icon icon={icon} />
      </Sidebar.Nav.Icon>
      <Sidebar.Nav.Title>{title}</Sidebar.Nav.Title>
    </Sidebar.Nav.Link>
  ) : (
    <></>
  );
};
/*
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar-expand"

*/
