import React, { CSSProperties } from 'react';

import clsx from 'clsx';

import mstyle from './style.module.scss';

interface props {
  children?: React.ReactNode;
  style?: CSSProperties;
}

const CentralFrame = ({ children, style }: props): React.JSX.Element => {
  return (
    <>
      <center>
        <div className={clsx(mstyle.frame)} style={style}>
          {children}
        </div>
      </center>
    </>
  );
};

export default CentralFrame;
