export const environment = process.env.REACT_APP_ENV as string;
export const githash = process.env.REACT_APP_FE as string;
export const apiHost = process.env.REACT_APP_API_HOST as string;
export const gatewayHost = process.env.REACT_APP_GATEWAY_HOST as string;

export const merchantportalHost = process.env.REACT_APP_MERCHANTPORTAL_HOST as string;
export const merchantportalDocHost = process.env.REACT_APP_MERCHANTPORTAL_DOC_HOST as string;
export const graphqlHost = process.env.REACT_APP_GRAPHQL_HOST as string;
export const loggerHost = process.env.REACT_APP_WSLOG_HOST as string;

export const useAutheid = process.env.REACT_APP_USE_AUTHEID as string;
export const useGraphql = process.env.REACT_APP_USE_GRAPHQL as string;
export const debug = process.env.REACT_APP_DEBUG as string;
export const autheidReconnect = process.env.REACT_APP_AUTHEID_RECONNECT as string;
export const graphqlReconnect = process.env.REACT_APP_GRAPHQL_RECONNECT as string;
export const graphqlAutoConnect = process.env.REACT_APP_GRAPHQL_AUTOCONNECT as string;
export const autheidAutoConnect = process.env.REACT_APP_AUTHEID_AUTOCONNECT as string;
export const autheidDisconnectOnClaim = process.env.REACT_APP_AUTHEID_DISCONNECT_ONCLAIM as string;
export const graphqlDisconnectOnClaim = process.env.REACT_APP_GRAPHQL_DISCONNECT_ONCLAIM as string;

export const autheidServiceUrl = process.env.REACT_APP_AUTHEID_SERVICE_URL as string;

export const autheidWsHost = process.env.REACT_APP_AUTHEID_WS_HOST as string;
export const graphqlWsHost = process.env.REACT_APP_GRAPHQL_WS_HOST as string;
export const bankidWsHost = process.env.REACT_APP_BANKID_WS_HOST as string;
export const trysettleApiHost = process.env.REACT_APP_TRYSETTLE_API_HOST as string;

export const settleHost = process.env.REACT_APP_SETTLE_HOST as string;
export const twitterLink = process.env.REACT_APP_TWITTER as string;
export const githubLink = process.env.REACT_APP_GITHUB as string;
export const telegramLink = process.env.REACT_APP_TELEGRAM as string;
export const email = process.env.REACT_APP_EMAIL as string;

export const googleArea = process.env.REACT_APP_GOOGLE_AREA as string;
export const googleProject = process.env.REACT_APP_GOOGLE_PROJECT as string;
export const googleDomain = process.env.REACT_APP_GOOGLE_DOMAIN as string;

export const settleReturnSuccessUrl = process.env.REACT_APP_SETTLE_RETURN_SUCCESS_URL as string;
export const settleReturnFailureUrl = process.env.REACT_APP_SETTLE_RETURN_FAILURE_URL as string;

export const RootPrefix = '/';
export const AdminPrefix = '/admin';
export const MerchantPrefix = '/merchant';
// URLS
export enum AdminUrl {
  MAIN = '',
  DASHBOARD = 'dashboard',
  TRANSACTION_HISTORY = 'transaction-history',
  MERCHANT_TRANSACTIONS = 'merchant-transactions',
  API_KEYS = 'api-keys',
  API_KEYS_ADD = 'api-keys/add',
  PROFILE = 'profile',
  RFQ_LIST = 'rfq',
  CLIENT_PAYOUT = 'client-payout',
  DEPOSIT = 'deposit',
  WITHDRAWALS = 'withdrawals',
  UNHANDLED_WITHDRAWALS = 'unhandled-withdrawals',
  BATCHES = 'batches',
  MERCHANTS = 'clients',
  MERCHANTS_PENDING = 'clients/pending',
  MERCHANTS_COUNTERPARTY = 'clients/counterparty',
  MERCHANTS_ADD = 'clients/add',
  CUSTOMERS = 'customers',
  PROXY = 'proxy',
  COMPLIANCE = 'compliance'
}

export enum PageUrl {
  LANDING = '',
  CONTACT_SALES = 'contact-sales',
  REGISTER = 'register',
  LOGIN = '#login',
  TERMS_OF_USE = 'terms-of-use',
  PRIVACY_POLICY = 'privacy-policy',
  REGULATION = 'regulation'
}

export enum Service {
  GRAPHQL = 'graphql',
  AUTHEID = 'autheid',
  STATUS = 'status',
  SERVICEWORKER = 'sw'
}

export enum Method {
  START_TOKEN_RENEW = 'startRefreshTimer',
  STOP_TOKEN_RENEW = 'stopRefreshTimer',
  QUERY = 'query',
  GATEWAY = 'gateway',
  CONNECT = 'connect',
  PDISCONNECT = 'permanentDisconnect',
  DISCONNECT = 'disconnect',
  AUTH = 'auth',
  AUTHORIZE = 'authorize',
  AUTHORIZED = 'authorized',
  RESPONSE = 'response',
  STATUS = 'status'
}

export enum NotifyType {
  UPDATE = 'update',
  ERROR = 'error',
  ACCOUNT = 'account',
  SELECTED = 'selected',
  SIGN = 'sign',
  KYC = 'KYC',
  STATE = 'state',
  PAYMENT = 'payment',
  WITHDRAWAL = 'WITHDRAWAL'
}

export const serviceWorkerEventName = 'message';
export const cookieToken = 'कुकी टोकन';
export const cookieTokenPub = 'प्रसन्नः';
