import { Modal } from 'react-bootstrap';
import React, { ReactNode } from 'react';

import Button from '../../../shared-components/components/Button';

interface Props {
  title: string;
  visible: boolean;
  handleClose?: () => void;
  onOk?: () => void;
  children?: ReactNode;
}

const ConfirmModal = ({ title, handleClose, visible, onOk }: Props) => {
  return (
    <Modal show={visible} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="text-center px-2 py-5 font-weight-bold text-uppercase" style={{ fontSize: 22 }}>
        {title}
      </Modal.Body>
      <Modal.Footer className="pt-0 border-top-0 justify-content-center gap-3">
        <Button variant="danger" className="px-4" onClick={onOk}>
          Ok
        </Button>
        <Button variant="secondary" className="px-3" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
