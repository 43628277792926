import React, { CSSProperties } from 'react';

import { clsx } from 'clsx';

import { BaseProps } from '../../../utils/types';

import styles from './style.module.scss';

interface PageContentProps {
  title?: string;
  classContainer?: string;
  className?: string;
  style?: CSSProperties;
}

export const PageContent = ({ children, title, classContainer, className, style }: PageContentProps & BaseProps) => {
  return (
    <div className={clsx(styles.container, classContainer)}>
      <div className={className} style={style}>
        <h1 className={styles.title}>{title}</h1>
        {children}
      </div>
    </div>
  );
};
