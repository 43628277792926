import React, { createContext, useContext, useEffect, useState } from 'react';

import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { BaseProps } from 'shared-components/utils/types';
import useSWComm from 'shared-components/hooks/useSWComm';

import { useAuthEID } from './AuthEIDProvider';
import { useApp } from './AppProvider';

type GraphQLContextProps = {
  authorized: boolean;
  connected: boolean;
  disconnect: () => void;
};

const GraphQLContext = createContext<GraphQLContextProps>({
  authorized: false,
  connected: false,
  disconnect: function (): void {
    throw new Error('Function not implemented.');
  }
});
let pingTimer: string | number | NodeJS.Timeout | undefined;

export const useGraphQL = () => {
  const context = useContext(GraphQLContext);
  if (!context) {
    throw new Error('GatewayContext not available');
  }
  return context;
};

const GraphQLProvider = ({ children }: BaseProps) => {
  const {
    values: { status, serviceWorkerReady, needAuth },
    setters: { setNeedAuth }
  } = useApp();
  const { token } = useAuthEID();
  const [authorized, setAuthorized] = useState(false);
  const [connected, setConnected] = useState(false);

  const disconnectQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: 'disconnect',
    returnObjectName: 'undefined',
    data: {},
    auto: false
  });

  const statusQuery = useSWQuery({
    service: Service.STATUS,
    method: Method.STATUS,
    returnObjectName: 'undefined',
    data: {},
    auto: false
  });

  /*
  const ping = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'nonexistent',
    data: {
      query: '{ ping }'
    },
    auto: false
  });
*/
  useSWComm({
    service: Service.GRAPHQL,
    method: Method.RESPONSE,
    onResponse: (data: any) => {
      if (data?.message === 'service_error') {
        disconnectQuery.execute();
      }
    }
  });

  useSWComm({
    service: Service.GRAPHQL,
    method: Method.CONNECT,
    onResponse: (data: any) => {
      setConnected(data);
      if (data === false) {
        statusQuery.execute();
      }
    }
  });

  useSWComm({
    service: Service.GRAPHQL,
    method: Method.AUTHORIZED,
    onResponse: (data: any) => {
      setAuthorized(data);
      if (data === true) {
        statusQuery.execute();
      }
    }
  });

  const authorize = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.AUTHORIZE,
    returnObjectName: 'auth',
    data: {},
    auto: false
  });

  useEffect(() => {
    if (token.length > 0 && needAuth === true) {
      authorize.execute({ access_token: token });
      setNeedAuth(false);
    }
  }, [token, needAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authorized === true) {
      pingTimer = setInterval(() => {
        //        ping.execute();
      }, 30_000);
    } else {
      clearInterval(pingTimer);
      pingTimer = undefined;
    }
  }, [authorized]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!serviceWorkerReady) {
      return;
    }
    setAuthorized(status.graphqlAuthorized);
    setConnected(status.graphqlConnected);
  }, [serviceWorkerReady, status.graphqlAuthorized, status.graphqlConnected]);

  return (
    <GraphQLContext.Provider
      value={{
        authorized,
        connected,
        disconnect: () => {
          disconnectQuery.execute();
        }
      }}
    >
      {children}
    </GraphQLContext.Provider>
  );
};

export default GraphQLProvider;
