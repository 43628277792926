import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { BaseProps } from '../../shared-components/utils/types';
import Login from 'shared-components/components/Modals/Login';
import { useApp } from 'shared-components/providers/AppProvider';
import { useHash } from 'shared-components/hooks/useHash';

import Header from './Header';
import SideBar from './SideBar';
import Main from './Main';

import 'react-toastify/dist/ReactToastify.min.css';

export default function Layout({ children }: BaseProps) {
  const {
    values: { loggedIn }
  } = useApp();

  const { updateHash } = useHash();

  useEffect(() => {
    if (loggedIn === false) {
      updateHash('#login');
    } else {
      updateHash('#');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);
  return (
    <>
      <Login />
      <div className="main-wrapper">
        <Header />
        <SideBar />
        <Main>
          <Outlet />
          {children}
        </Main>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
}
