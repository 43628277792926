import { TableColumn } from 'react-data-table-component';

import { TransactionHistoryRow } from '../../../types/transactionHistory';

export const columns: TableColumn<TransactionHistoryRow>[] = [
  {
    name: 'CLIENT REF',
    selector: (row: TransactionHistoryRow) => row.clientRef ?? '',
    wrap: true
  },
  {
    name: 'ACCOUNT NAME',
    selector: (row: TransactionHistoryRow) => row.accountName ?? '',
    wrap: true
  },
  {
    name: 'DATE',
    selector: (row: TransactionHistoryRow) => row.sourced ?? '',
    grow: 1,
    wrap: false
  },
  {
    name: 'SOURCE',
    selector: (row: TransactionHistoryRow) => row.source ?? '',
    wrap: true,
    conditionalCellStyles: [
      {
        when: (row: any) => row.source.includes('WITHDRAW'),
        style: {
          fontWeight: '600',
          color: 'darkred'
        }
      },
      {
        when: (row: any) => row.source.includes('FEE'),
        style: {
          fontWeight: '600',
          color: 'darkblue'
        }
      },
      {
        when: (row: any) => row.source.includes('PAYOUT'),
        style: {
          fontWeight: '600',
          color: 'darkred'
        }
      },
      {
        when: (row: any) => row.source.includes('DEPOSIT'),
        style: {
          fontWeight: '600',
          color: 'darkgreen'
        }
      }
    ]
  },
  {
    name: 'DESCRIPTION',
    selector: (row: TransactionHistoryRow) => row.description ?? '',
    wrap: true
  },
  {
    name: 'AMOUNT',
    selector: (row: TransactionHistoryRow) => row.currency ?? '',
    wrap: true,
    width: '200px',
    right: true
  }
];
