// src/serviceWorker.ts

import {
  githash,
  autheidWsHost,
  graphqlWsHost,
  debug,
  autheidReconnect,
  graphqlReconnect,
  autheidAutoConnect,
  graphqlAutoConnect,
  autheidDisconnectOnClaim,
  graphqlDisconnectOnClaim,
  useAutheid,
  useGraphql
} from './configuration';

type ServiceWorkerRegisterProps = {
  url: string;
  scope: string;
  onReady?: () => void;
};

const serviceworkerRegister = ({ url, scope, onReady }: ServiceWorkerRegisterProps) => {
  navigator.serviceWorker.controller?.addEventListener('statechange', (_event: any) => {
    //
  });

  navigator.serviceWorker
    .register(url, {
      type: 'classic',
      scope: scope,
      updateViaCache: 'none'
    })
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      onReady?.call(this);
      setTimeout(() => {
        registration?.active?.postMessage({ service: 'claim' });
      }, 2000);
      /*
      setInterval(() => {
        registration.update();
      }, 4000);
      */
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
};

export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = new URL(`${window.location.origin}/sw.js`);
      swUrl.searchParams.set('debug', debug);
      swUrl.searchParams.set('rev', githash);
      if (useAutheid) {
        swUrl.searchParams.set('autheidHost', autheidWsHost);
        swUrl.searchParams.set('autheidReconnect', autheidReconnect);
        swUrl.searchParams.set('autheidAutoConnect', autheidAutoConnect);
        swUrl.searchParams.set('autheidDisconnectOnClaim', autheidDisconnectOnClaim);
      }
      if (useGraphql) {
        swUrl.searchParams.set('graphqlHost', graphqlWsHost);
        swUrl.searchParams.set('graphqlReconnect', graphqlReconnect);
        swUrl.searchParams.set('graphqlAutoConnect', graphqlAutoConnect);
        swUrl.searchParams.set('graphqlDisconnectOnClaim', graphqlDisconnectOnClaim);
      }
      serviceworkerRegister({
        url: swUrl.toString(),
        scope: '/'
      });
      navigator.serviceWorker.addEventListener('updatefound', () => {
        console.log('Service Worker update detected!');
      });
      navigator.serviceWorker.addEventListener('controllerchange', function () {
        App.sendMessage({ service: 'status' });
      });

      navigator.serviceWorker.addEventListener('message', (event) => {
        App.logData(event);
      });
      navigator.serviceWorker.startMessages();
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
        //        clearInterval(timer2);
      })
      .catch((error) => {
        console.error('Error during service worker unregistration:', error);
      });
  }
}
