import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import { CREATE_RFQ, TOPUP_RFQ } from 'shared-components/queries/mutations';
import { PendingPaymentType } from 'types/grapql';
import CopyButton from 'shared-components/components/CopyButton';
import { DEPOSIT_DETAILS } from 'shared-components/queries/graphql';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';
import { environment } from 'shared-components/configuration';

import style from './style.module.scss';

const Deposit = () => {
  const [rfqData, setRfqData] = useState<PendingPaymentType | null>(null);
  const [depositDetails, setDepositDetails] = useState<any>([]);
  const merchantInfo = useMerchantInfo();

  const depositOrderData = useMemo(() => {
    let iban = '';
    let bic = '';
    let accountName = '';
    const bankName = '';
    let accountAddress = '';
    let bankCountry = '';
    if (depositDetails && depositDetails?.length > 0) {
      const data = depositDetails[0];
      const street_line1 = data.beneficiary_address.street_line1 || '';
      const city = data.beneficiary_address.city || '';
      bic = data?.bic;
      iban = data?.iban;
      accountName = data?.beneficiary;
      accountAddress = street_line1 && city ? `${street_line1}, ${city}` : street_line1 || city;
      iban = data?.iban;
      bankCountry = data?.bank_country;
    }

    return { bic, accountName, bankName, accountAddress, bankCountry, iban };
  }, [depositDetails]);

  const getRfq = useCallback(() => {
    App.sendMessage({
      service: 'graphql',
      data: {
        query: CREATE_RFQ,
        params: {
          variables: {
            amount: '0',
            currency: 'SEK',
            topup: false
          }
        }
      }
    });
  }, []);

  const getDepositDetail = useCallback(() => {
    App.sendMessage({
      service: 'graphql',
      data: {
        query: DEPOSIT_DETAILS,
        params: {
          variables: {
            currency: 'SEK'
          }
        }
      }
    });
  }, []);

  useEffect(function setupListener() {
    function handleListener(event: MessageEvent<any>) {
      if (event.data.system === 'graphql' && event.data.method === 'response' && event.data.data.data?.createRfq) {
        setRfqData(event.data.data.data.createRfq.rfq);
      }
      if (event.data.system === 'graphql' && event.data.method === 'response') {
        if (event.data.data.data?.createRfq) {
          setRfqData(event.data.data.data.createRfq.rfq);
        }
        if (event.data.data.data?.depositDetails) {
          setDepositDetails(JSON.parse(event.data.data.data?.depositDetails));
        }
      }
    }
    App.channel.addEventListener('message', handleListener);

    return function cleanupListener() {
      App.channel.removeEventListener('message', handleListener);
    };
  }, []);

  useEffect(() => {
    getRfq();
    getDepositDetail();
  }, [getDepositDetail, getRfq]);

  const handleTopup = useCallback(
    (_: any) => {
      App.sendMessage({
        service: 'graphql',
        data: {
          query: TOPUP_RFQ,
          params: {
            variables: {
              amount: 5,
              currency: 'SEK',
              status: 'completed',
              txRef: rfqData?.txRef
            }
          }
        }
      });
    },
    [rfqData?.txRef]
  );
  return (
    <>
      <Card className="px-3 pt-3 pb-4 rounded-0 shadow bg-white overflow-auto">
        <Row>
          <Col sm={12} md={12} lg={6}>
            <h1 className={style.h1}>EUR Deposit Instructions</h1>
            <div>
              You can make deposits in <strong>EUR</strong> to the below bank account
            </div>
            <center>
              <Table border={1} cellPadding={20} size={'lg'} className="mt-3">
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: '#dfe2e6' }}>IBAN</td>
                    <td className="d-flex align-items-center" style={{ minHeight: '50px' }}>
                      {depositOrderData.iban && (
                        <div>
                          {depositOrderData?.iban} &nbsp;
                          <CopyButton value={depositOrderData?.iban} isBlack />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#dfe2e6' }}>BIC/SWIFT</td>
                    <td className="d-flex align-items-center" style={{ minHeight: '50px' }}>
                      {depositOrderData.bic}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#dfe2e6' }}>Account Name</td>
                    <td className="d-flex align-items-center" style={{ minHeight: '50px' }}>
                      {depositOrderData.accountName}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ backgroundColor: '#dfe2e6' }}>Account Address</td>
                    <td className="d-flex align-items-center" style={{ minHeight: '50px' }}>
                      {depositOrderData.accountAddress}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#dfe2e6' }}>Bank Country</td>
                    <td className="d-flex align-items-center" style={{ minHeight: '50px' }}>
                      {depositOrderData.bankCountry}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </center>
            <p style={{ fontSize: '0.9rem' }}>
              Infinipi will charge {merchantInfo.depositFee}% on all your SEPA eur deposits, for further assistnce and
              clarifications you may contact support
            </p>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <center>
              <h1 className={style.h1}>Deposit Reference</h1>
              <div style={{ fontSize: '1.2rem' }}>
                <FontAwesomeIcon icon={faCircleExclamation} color="blue" /> It is vital that you quote your Deposit
                Reference as part of the bank transfer
              </div>
              <div className="border border-dark mt-3 p-3 d-flex flex-column align-items-center ">
                <p style={{ fontSize: '12px', textAlign: 'left' }}>Your Deposit Reference*</p>
                <div className="d-flex align-items-center">
                  {rfqData?.txRef && (
                    <>
                      <span className="" style={{ fontSize: '20px' }}>
                        {rfqData?.txRef} &nbsp;
                      </span>
                      <CopyButton value={rfqData?.txRef} isBlack />
                    </>
                  )}
                </div>
              </div>
            </center>
            <br />
            <p style={{ fontSize: '0.9rem' }}>
              *Your deposit reference is unique to your account and helps us process your payment. Include it in the
              reference field when making your bank transfer.
            </p>
          </Col>
        </Row>
      </Card>
      {environment === 'staging' && (
        <>
          <Button variant="primary" size="lg" onClick={handleTopup}>
            TOPUP
          </Button>
          &nbsp;
        </>
      )}
    </>
  );
};
export default Deposit;
