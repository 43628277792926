import React from 'react';
import { Link } from 'react-router-dom';

import { email, githubLink, telegramLink, twitterLink } from '../../shared-components/configuration';
import Icon from '../Icon';
import { Envelope, Github, Telegram, Twitter } from '../../shared-components/assets/icons';

import style from './style.module.scss';

const ContactBox = (): React.JSX.Element => {
  return (
    <>
      <div className={style.contactbox}>
        <div className={style.title}>Contacts</div>
        <div className={style.email}>
          <Link to={'mailto:' + email} className={style.link}>
            <div className={style.inline}>
              <Icon icon={Envelope} className={style.gray} />
              &nbsp;{email}
            </div>
          </Link>
        </div>
        <div className={style.links}>
          <Link to={twitterLink}>
            <Icon icon={Twitter} className={style.gray} />
          </Link>
          <Link to={telegramLink}>
            <Icon icon={Telegram} className={style.gray} />
          </Link>
          <Link to={githubLink}>
            <Icon icon={Github} className={style.gray} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default ContactBox;
