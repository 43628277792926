import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import { WITHDRAW_QUERY } from 'shared-components/queries/graphql';
import WithdrawModal from '../Modals/WithdrawModal';
import { useBalances } from '../../contexts/balances';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';

const Withdrawbox = () => {
  const { refreshBalances } = useBalances();
  const amountRef = createRef<any>();
  const [amount, setAmount] = useState(0);
  const [show, setShow] = useState(false);
  const balances = useBalances();

  const eurBalance = useMemo(() => {
    const broker = balances?.balances?.filter((account: any) => {
      return account.accountType === 'Broker';
    });
    if (!broker) {
      return (0).toFixed(2);
    }
    const amount = broker[0]?.balance?.filter((balance: any) => {
      return balance?.currencyName === 'EURX';
    })?.[0]?.amount;
    return Number.parseFloat(amount).toFixed(2);
  }, [balances?.balances]);

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'withdraw',
    data: {
      query: WITHDRAW_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      if (data === true) refreshBalances();
      setShow(true);
    }
  });

  const handleCloseWithdrawModal = useCallback(() => {
    setShow(false);
  }, []);

  const onAmountChanged = useCallback((event: any) => {
    setAmount(event.target.value);
  }, []);

  const onWithdraw = useCallback(
    (event: any) => {
      event.preventDefault();
      query.execute({ variables: { amount: amount, ccyId: 'EUR' } });
    },
    [amount, query]
  );

  useEffect(() => {
    if (show === true) {
      amountRef.current.value = '';
    }
  }, [amountRef, show]);

  return (
    <>
      <WithdrawModal show={show} handleClose={handleCloseWithdrawModal} />
      <Container>
        <Form onSubmit={onWithdraw}>
          <Row>
            <Col>
              <Form.Control
                name="amount"
                type="number"
                placeholder="Enter EUR amount to withdraw"
                onChange={onAmountChanged}
                ref={amountRef}
                step={0.01}
                required
                max={eurBalance}
              />
            </Col>
            <Col xs={'2'} sm={'2'} md={'2'} lg={'3'} xl={'3'}>
              <Button type="submit">Withdraw</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Withdrawbox;
