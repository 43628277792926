import { useEffect, useState } from 'react';

import { serviceWorkerEventName } from '../configuration';

type SWCommProps = {
  service: string;
  method: string;
  messageId?: string;
  onResponse?: (data: any) => void;
};

const useSWComm = ({ service, method, onResponse }: SWCommProps) => {
  const [value, setValue] = useState<any>(null);
  useEffect(
    function setupListener() {
      function handleListener(event: MessageEvent<any>) {
        if (
          //          event.data.message_id === messageId &&
          event.data.service === service &&
          event.data.method === method &&
          event.data.data
        ) {
          onResponse?.call(undefined, event.data.data);
          setValue(event.data.data);
        }
      }
      window.App.channel.addEventListener(serviceWorkerEventName, handleListener);

      return function cleanupListener() {
        window.App.channel.removeEventListener(serviceWorkerEventName, handleListener);
      };
    },

    [service, method, onResponse]
  );
  return value;
};

export default useSWComm;
