import { useEffect, useState } from 'react';

import { Method, serviceWorkerEventName } from '../configuration';

type SWSignalProps = {
  service: string;
  notifyType: string;
  onNotify?: (data: any) => void;
};

const useSWSignal = ({ service, notifyType, onNotify }: SWSignalProps) => {
  const [value, setValue] = useState<any>([]);
  useEffect(
    function setupListener() {
      const handleListener = (event: MessageEvent<any>) => {
        if (
          event.data.service === service &&
          event.data.method === Method.RESPONSE &&
          event.data.data?.notify_type == notifyType
        ) {
          onNotify?.call(undefined, event.data.data.data);
          setValue(event.data.data.data);
        }
      };
      App.channel.addEventListener(serviceWorkerEventName, handleListener);

      return function cleanupListener() {
        App.channel.removeEventListener(serviceWorkerEventName, handleListener);
      };
    },
    [notifyType, service, onNotify]
  );
  return value;
};

export default useSWSignal;
