import { Col, Row } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import React, { useCallback, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { usePapaParse } from 'react-papaparse';
import DataTable, { TableColumn } from 'react-data-table-component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import { ClientPayoutType } from 'shared-components/types/ClientPayoutType';
import { CREATE_BATCHED_CLIENT_PAYOUT } from 'shared-components/queries/mutations';
import Button from 'shared-components/components/Button';
import MakePaymentResultModal from 'components/Modals/MakePaymentResultModal';
import Icon from 'components/Icon';
import { UploadFile, UploadFileSuccess } from 'shared-components/assets/icons';

import style from './style.module.scss';

const InitPaymentStatus = { isOpen: false, isError: true, message: '' };

const EmptyFile = { name: '', bulkData: [] };

export const BatchFrom = () => {
  const [batchResult, setBatchResult] = useState<any[]>([]);
  const { readString } = usePapaParse();
  const [fileData, setFileData] = useState(EmptyFile);
  const [makePaymentStatusModal, setMakePaymentStatusModal] = useState(InitPaymentStatus);

  const handleBulkPayout = useCallback(
    (file: File) => {
      const fileName = file.name;
      file.text().then((csvData) => {
        readString(csvData, {
          worker: true,
          header: false,
          skipEmptyLines: true,
          complete: (results: any) => {
            setFileData({
              name: fileName,
              bulkData: results.data
            });
          }
        });
      });
    },
    [readString]
  );

  const removeFile = () => {
    setFileData(EmptyFile);
    setBatchResult([]);
  };

  useEffect(() => {
    if (fileData.bulkData.length > 0) {
      const csvArray: ClientPayoutType[] = [];
      for (const element of fileData.bulkData.slice(1) as any) {
        csvArray.push({
          name: element[0],
          recipientType: element[1],
          iban: element[2],
          bic: element[3],
          recipientBankCountry: element[4],
          currency: element[5],
          amount: Number.parseFloat(element[6].replace(',', '.')),
          paymentReference: element[7],
          recipientCountry: element[8],
          addressLine1: element[9],
          addressLine2: element[10],
          city: element[11],
          postalCode: element[12]
        });
      }

      App.sendMessage({
        service: 'graphql',
        data: {
          query: CREATE_BATCHED_CLIENT_PAYOUT,
          params: {
            variables: {
              paymentInfoList: csvArray
            }
          }
        }
      });
    }
  }, [fileData.bulkData]);

  useEffect(function setupListener() {
    function handleListener(event: MessageEvent<any>) {
      if (event.data.system === 'graphql' && event.data.method === 'response') {
        const data = event.data.data.data;
        if (!data) {
          return;
        }

        if (data.createBatchedClientPayout) {
          setBatchResult(data.createBatchedClientPayout.result);
        }
      }
    }
    App.channel.addEventListener('message', handleListener);

    return function cleanupListener() {
      App.channel.removeEventListener('message', handleListener);
    };
  }, []);

  return (
    <div>
      <div
        style={{
          border: '1px dashed #D2D2D2',
          height: 120,
          width: '100%',
          position: 'relative'
        }}
      >
        <div className={style.batchLabel}>Batch payout</div>
        {fileData.name && fileData.bulkData && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 3
            }}
          >
            <Icon icon={UploadFileSuccess} style={{ paddingRight: 2 }} />
            <span style={{ color: '#000' }}>{fileData.name}</span>
            <Button variant="link" onClick={removeFile} style={{ color: '#A9A9A9', padding: 10 }}>
              <span style={{ textDecoration: 'underline' }}>Delete</span>
            </Button>
          </div>
        )}
        <FileUploader
          onSelect={handleBulkPayout}
          onDrop={handleBulkPayout}
          types={['csv']}
          classes={style.uploadFile}
          disabled={!!fileData.name && !!fileData.bulkData}
        >
          <div className="d-flex justify-content-center align-items-center">
            {!fileData.name && fileData.bulkData.length === 0 && (
              <>
                <Icon icon={UploadFile} style={{ paddingRight: 2 }} />
                <span style={{ color: '#A9A9A9' }}>
                  <span style={{ textDecoration: 'underline' }}>Upload</span> or drop a csv file right here
                </span>
              </>
            )}
          </div>
        </FileUploader>
      </div>
      <Row className="mt-3">
        <Col md={{ span: 6, offset: 6 }}>
          <a href={'/files/example.csv'}>
            <Button className="w-100 rounded-pill" variant="outline-primary">
              CSV template download
            </Button>
          </a>
        </Col>
      </Row>
      {batchResult && batchResult.length ? (
        <div className="mt-4">
          <DataTable columns={columns} data={batchResult ?? []} />
        </div>
      ) : null}

      <MakePaymentResultModal
        isOpen={makePaymentStatusModal.isOpen}
        isError={makePaymentStatusModal.isError}
        message={makePaymentStatusModal.message}
        onClose={() => setMakePaymentStatusModal(InitPaymentStatus)}
      />
    </div>
  );
};

export const columns: TableColumn<ClientPayoutType>[] = [
  {
    name: 'name',
    selector: (row: ClientPayoutType) => row.name ?? ''
  },
  {
    name: 'iban',
    selector: (row: ClientPayoutType) => row.iban ?? ''
  },
  {
    name: 'amount',
    selector: (row: ClientPayoutType) => `${row.amount}`
  },

  {
    name: 'currency',
    selector: (row: ClientPayoutType) => `${row.currency}`
  },
  {
    name: 'status',
    selector: (row: ClientPayoutType) => row.error ?? false,
    cell: (row) =>
      row.error ? <FontAwesomeIcon icon={faXmark} color="red" /> : <FontAwesomeIcon icon={faCheck} color="green" />
  }
];
