import React from 'react';

//import { useApp } from 'shared-components/providers/AppProvider';
import { BaseProps } from '../../../shared-components/utils/types';
import { useApp } from 'shared-components/providers/AppProvider';

const Main = ({ children }: BaseProps) => {
  const {
    values: { loggedIn }
  } = useApp();

  return (
    <main id="scrollableMain" className="main-container container-fluid ">
      {loggedIn ? children : <></>}
    </main>
  );
};

export default Main;
