import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Col, Row } from 'react-bootstrap';

import { TransactionHistoryRow } from '../../../types/transactionHistory';
import {
  ADMIN_MERCHANTTRANSACTIONS_QUERY,
  ADMIN_TRANSACTIONSALL_QUERY,
  TRANSACTIONS_QUERY
} from 'shared-components/queries/graphql';
import { MerchantTransaction } from '../../../types/grapql';
import DropdownSelector from '../../../components/DropdownSelector';
import { useMerchants } from '../../../contexts/GraphQL';
import { DropdownValue } from '../../../types/dropdownvalue';
//import { useApp } from 'shared-components/providers/AppProvider';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';

import { columns } from './tablecolumn';

const customStyles = {
  headCells: {
    style: {
      backgroundColor: 'lightgray'
    }
  }
};

const MerchantTransactionsUser = () => {
  const [data, setData] = useState([]);
  const transactions = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'merchantTx',
    data: {
      query: TRANSACTIONS_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      setData(data);
    }
  });

  useEffect(() => {
    transactions.execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tableData = useMemo(() => {
    let rows: TransactionHistoryRow[] = [];
    for (const element of data as MerchantTransaction[]) {
      if (!element.id || element.currencyId !== 2 || element.source === 'intital_credit') {
        return;
      }
      rows = [
        ...rows,
        {
          source: element.source?.toUpperCase(),
          sourced: element.sourced,
          currency: `${element.amount} EUR`,
          description: element.description,
          clientRef: element.clientRef
        }
      ];
    }
    return rows;
  }, [data]);

  return (
    <>
      <DataTable
        columns={columns}
        data={tableData ?? []}
        customStyles={customStyles}
        className="shadow p-3 mb-5 bg-white"
      />
    </>
  );
};

const MerchantTransactionsAdmin = () => {
  const { merchants } = useMerchants();
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState(false);
  const [tableData, setTableData] = useState<TransactionHistoryRow[]>([]);
  const addPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const getTransactions = useCallback((merchantId: string) => {
    App.sendMessage({
      service: 'graphql',
      data: {
        query: ADMIN_MERCHANTTRANSACTIONS_QUERY,
        params: { variables: { merchantId: merchantId } }
      }
    });
  }, []);

  const getAllTransactions = useCallback(() => {
    App.sendMessage({
      service: 'graphql',
      data: {
        query: ADMIN_TRANSACTIONSALL_QUERY,
        params: { variables: { page: page, perPage: 50 } }
      }
    });
  }, [page]);

  useEffect(
    function setupListener() {
      function handleListener(event: MessageEvent<any>) {
        if (event.data.system === 'graphql' && event.data.method === 'response') {
          let rows: TransactionHistoryRow[] = [];
          if (allData) {
            for (const element of event.data.data?.data?.merchantTxAll as MerchantTransaction[]) {
              if (!element.id) {
                return;
              }
              let currency = 'N/A';
              switch (element.currencyId) {
                case 1: {
                  currency = 'USDT';
                  break;
                }
                case 2: {
                  currency = 'EUR';
                  break;
                }
                case 5: {
                  currency = 'SEK';
                  break;
                }
                default: {
                  currency = 'N/A';
                }
              }

              rows = [
                ...rows,
                {
                  accountName: element.accountId,
                  source: element.source?.toUpperCase(),
                  sourced: element.sourced,
                  currency: `${element.amount} ${currency}`,
                  description: element.description,
                  clientRef: element.clientRef
                }
              ];
            }
          } else {
            for (const element of event.data.data?.data?.merchantTx as MerchantTransaction[]) {
              if (!element.id || element.currencyId !== 2 || element.source === 'intital_credit') {
                return;
              }

              rows = [
                ...rows,
                {
                  accountName: element.accountName,
                  source: element.source?.toUpperCase(),
                  sourced: element.sourced,
                  currency: `${element.amount} EUR`,
                  description: element.description,
                  clientRef: element.clientRef
                }
              ];
            }
          }
          if (rows.length > 0) {
            if (allData && page !== 1) {
              setTableData((prev) => [...prev, ...rows]);
            } else {
              setTableData(rows);
            }
          }
        }
      }
      App.channel.addEventListener('message', handleListener);

      return function cleanupListener() {
        App.channel.removeEventListener('message', handleListener);
      };
    },
    [allData, page]
  );

  const [merchantDropdowns, setMerchantDropdowns] = useState<DropdownValue[]>([
    { line1: 'SHOW ALL', line2: '', value: null }
  ]);

  function parseMerchant(merchant: any) {
    setMerchantDropdowns((prevstate) => [
      ...(prevstate ?? []),
      { line1: merchant.name, line2: '', value: merchant.id }
    ]);
  }
  useEffect(() => {
    for (const element of merchants) {
      parseMerchant(element);
    }
  }, [merchants]);

  const onChangePage = useCallback(() => {
    if (allData) {
      addPage();
      getAllTransactions();
    }
  }, [addPage, allData, getAllTransactions]);

  const onDropdownChange = useCallback(
    (value: any) => {
      if (value.value) {
        setAllData(false);
        getTransactions(value.value);
      } else {
        setAllData(true);
        setPage(1);
        getAllTransactions();
      }
    },
    [getAllTransactions, getTransactions]
  );

  return (
    <>
      <Row>
        <Col>
          <DropdownSelector values={merchantDropdowns} onChange={onDropdownChange} />
        </Col>
      </Row>

      <InfiniteScroll
        next={onChangePage}
        hasMore={true}
        loader={<></>}
        dataLength={tableData?.length ?? 0}
        scrollableTarget="scrollableMain"
      >
        <DataTable
          columns={columns}
          data={tableData ?? []}
          customStyles={customStyles}
          className="shadow p-3 mb-5 bg-white"
        />
      </InfiniteScroll>
    </>
  );
};
/*

*/
const MerchantTransactions = () => {
  /*const { userData } = useApp();*/
  // eslint-disable-next-line no-constant-condition
  return <>{/* userData.isAdmin */ false ? <MerchantTransactionsAdmin /> : <MerchantTransactionsUser />}</>;
};

export default MerchantTransactions;
