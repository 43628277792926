import React from 'react';

import clsx from 'clsx';

import mstyle from './style.module.scss';

interface props {
  icon: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}
const Icon = ({ alt, icon, className, style }: props): React.JSX.Element => {
  return icon.length > 0 ? <img alt={alt} src={icon} className={clsx(mstyle.icon, className)} style={style} /> : <></>;
};

export default Icon;
