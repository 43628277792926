import { useCallback, useEffect, useState } from 'react';

import { mergeDeep } from 'shared-components/utils';
import { Method } from 'shared-components/configuration';

import useSWComm from './useSWComm';

type SWQueryProps = {
  auto?: boolean;
  service: string;
  method: string;
  returnObjectName: string;
  data?: any;
  onResponse?: (data: any) => void;
  onErrors?: (data: any) => void;
};

const useSWQuery = ({ auto = true, service, method, returnObjectName, data, onResponse, onErrors }: SWQueryProps) => {
  const [value, setValue] = useState<any>([]);
  const handleResponse = useCallback(
    (eventData: any) => {
      const result =
        returnObjectName === ''
          ? eventData
          : eventData?.data
            ? eventData?.data?.[returnObjectName]
            : eventData?.[returnObjectName];
      const errors = returnObjectName !== '' && eventData?.data ? eventData?.data?.error : eventData?.error;
      if (returnObjectName && errors) {
        onErrors?.call(null, errors);
      } else if (result) {
        onResponse?.call(null, result);
        setValue(result);
      } else if (result === null) {
        onResponse?.call(this, null);
        setValue(result);
      }
    },
    [returnObjectName, onErrors, onResponse]
  );

  useSWComm({ service, method: Method.RESPONSE, onResponse: handleResponse });

  const queryCallback = useCallback(
    (values?: any) => {
      const payload = {
        service,
        method,
        data: mergeDeep(data, values)
      };
      App.sendMessage(payload);
    },
    [data, method, service]
  );

  const execute = useCallback(
    (values?: any) => {
      queryCallback(values);
    },
    [queryCallback]
  );

  useEffect(() => {
    if (auto) {
      execute();
    }
  }, [auto]); // eslint-disable-line react-hooks/exhaustive-deps
  return { execute, value };
};

export default useSWQuery;
