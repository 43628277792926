import React from 'react';

import MerchantProfile from '../../../components/MerchantProfile';

const ClientsAdd = () => {
  return (
    <>
      <MerchantProfile data={{}} />
    </>
  );
};
export default ClientsAdd;
