import React from 'react';

import style from './style.module.scss';

const Chessboard1 = (): React.JSX.Element => (
  <>
    <div className={style.bg}>
      <div className={style.r1} />
      <div className={style.r2} />
      <div className={style.r3} />
      <div className={style.r4} />
    </div>
  </>
);

export default Chessboard1;
