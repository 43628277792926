import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { BaseProps } from '../shared-components/utils/types';
import { Method, Service, environment } from 'shared-components/configuration';
import { useApp } from 'shared-components/providers/AppProvider';
import { ACCOUNT_BALANCE_QUERY } from 'shared-components/queries/graphql';
import useSWQuery from 'shared-components/hooks/useSWQuery';

const BalancesContext = createContext<any>({});

export const useBalances = () => {
  const context = useContext(BalancesContext);
  if (!context) throw new Error('Error not found');
  return context;
};

export const BalancesProvider = ({ children }: BaseProps) => {
  const {
    values: { loggedIn }
  } = useApp();

  const [balances, setBalances] = useState();

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'accountBalance',
    data: {
      query: ACCOUNT_BALANCE_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      setBalances(data);
    }
  });

  const refreshBalances = useCallback(() => {
    if (environment.startsWith('admin') && loggedIn) {
      query.execute();
    }
  }, [loggedIn, query]);

  useEffect(() => {
    refreshBalances();
    /*
    const interval = setInterval(refreshBalances, 5000);
    return () => {
      clearInterval(interval);
    };
*/
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <BalancesContext.Provider value={{ balances, refreshBalances }}>{children}</BalancesContext.Provider>;
};
