import React, { useCallback, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';

import CentralFrame from '../../../components/CentralFrame';
import RegisterModal from '../../../components/Modals/RegisterModal';

const Register = () => {
  const [registerModalShow, setRegisterModalShow] = useState(false);

  const handleModalClose = useCallback(() => {
    setRegisterModalShow(false);
  }, []);
  /*
  const handleClick = useCallback(() => {

    api.signupMerchant(user, password, name).then((response) => {
      if (!response.error) {
        setRegisterModalShow(true);
      }
      //        environment === "dev" && setDebug(response);

      //        setModalVisible(true);
    });
  }, [iban, name, password, user]);
*/
  const handleClick = useCallback(() => {
    // EMPTY CALLBACK
  }, []);
  return (
    <>
      <RegisterModal show={registerModalShow} handleClose={handleModalClose}></RegisterModal>
      <CentralFrame>
        <center>
          <h2>Welcome</h2>
          <h1>Start your onboarding journey</h1>
        </center>
        <Form>
          <FloatingLabel controlId="onboardEmail" label="Email address" className="mb-3">
            <Form.Control name="email" type="email" placeholder="name@example.com" />
          </FloatingLabel>
          <FloatingLabel controlId="onboardPassword" label="Passsword" className="mb-3">
            <Form.Control name="password" type="password" placeholder="" />
          </FloatingLabel>
          <FloatingLabel controlId="onboardName" label="Name" className="mb-3">
            <Form.Control name="Company name" type="text" placeholder="your company name" />
          </FloatingLabel>
          <FloatingLabel controlId="onboardIban" label="IBAN" className="mb-3">
            <Form.Control name="iban" type="text" placeholder="IBAN" />
          </FloatingLabel>
        </Form>
        <div className="d-grid gap-2">
          <Button variant="primary" size="lg" onClick={handleClick}>
            Continue
          </Button>
        </div>
      </CentralFrame>
    </>
  );
};

export default Register;
