import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';

import clsx from 'clsx';

import { MerchantType } from '../../../types/grapql';
import { ADMIN_UPDATE_MERCHANT_BASIC_MUTATION } from 'shared-components/queries/graphql';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';

import style from './style.module.scss';

interface Props {
  children?: React.ReactNode;
  visible: boolean;
  handleClose?: () => void;
  onSuccess?: () => void;
  merchant?: MerchantType;
}

const MerchantApproveModal = ({ handleClose, visible, onSuccess, merchant }: Props) => {
  const [alertVisible, setAlertVisible] = useState(false);

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateMerchant',
    data: {
      query: ADMIN_UPDATE_MERCHANT_BASIC_MUTATION
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.success === true) {
        onSuccess?.();
      }
    }
  });

  useEffect(() => {
    if (visible) {
      setAlertVisible(false);
    }
  }, [visible]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setAlertVisible(false);
      //      const name = event.currentTarget.elements.name.value;
      const email = event.currentTarget.elements.email.value;
      const iban = event.currentTarget.elements.iban.value;
      // TODO handle approve pending merchant
      query.execute({
        variables: {
          id: merchant?.id,
          email: email,
          iban: iban,
          status: true
        }
      });
    },
    [merchant?.id, query]
  );

  return (
    <Modal show={visible} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <span className={style.header}>Approve Merchant</span>
        </Modal.Header>
        <Modal.Body>
          <center>
            <FloatingLabel controlId="name" label="Company name" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="text" size="sm" placeholder="Name" defaultValue={merchant?.name} />
            </FloatingLabel>

            <FloatingLabel controlId="email" label="Email address" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="email" size="sm" placeholder="" defaultValue={merchant?.email} />
            </FloatingLabel>

            <FloatingLabel controlId="iban" label="IBAN" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="text" size="sm" placeholder="iban" defaultValue={merchant?.iban} />
            </FloatingLabel>
          </center>
          <Alert variant={'danger'} show={alertVisible}>
            Error while approving merchant
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Approve</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default MerchantApproveModal;
