import { Modal } from 'react-bootstrap';
import React, { CSSProperties, ReactNode } from 'react';

import Button from '../../../shared-components/components/Button';

interface Props {
  title: string;
  visible: boolean;
  handleClose?: () => void;
  onOk?: () => void;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  size?: 'sm' | 'lg' | 'xl' | undefined;
}

const InfoModal = ({ title, handleClose, visible, onOk, children, style, className, size }: Props) => {
  return (
    <Modal
      show={visible}
      style={style}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={className}
      size={size}
    >
      <Modal.Header
        className="text-center px-2 py-5 font-weight-bold text-uppercase"
        style={{ alignSelf: 'center', fontSize: 22 }}
      >
        {title}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="pt-0 border-top-0 justify-content-center gap-3">
        <Button className="px-4" onClick={onOk}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
