import React, { useCallback, useRef } from 'react';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';

//import api from '../../../utils/api';
//import { useApp } from 'shared-components/providers/AppProvider';

import style from './style.module.scss';

interface Props {
  children?: React.ReactNode;
  visible: boolean;
  handleClose?: () => void;
  onSuccess?: () => void;
}

const CryptoKeysModal = ({ handleClose, visible }: Props) => {
  const prvkeyRef = useRef<HTMLTextAreaElement>(null);
  const pubkeyRef = useRef<HTMLTextAreaElement>(null);
  //  const app = useApp();
  //    const [alertVisible, setAlertVisible] = useState(false);

  const handleGenerateCryptoKeys = useCallback(async () => {
    /*
    const result: any = await api.generateKeyPair(app.userData.email);
    const privateKey = atob(result.private_key);
    const publicKey = atob(result.public_key);
    if (pubkeyRef.current !== null) {
      pubkeyRef.current!.value = publicKey;
    }
    if (prvkeyRef.current !== null) {
      prvkeyRef.current!.value = privateKey;
    }
    */
  }, []);

  const handleCopyPrivateKey = useCallback(() => {
    if (prvkeyRef.current !== null) {
      navigator.clipboard.writeText(prvkeyRef.current.value);
      //      prvkeyRef.current!.value =
      //        "PRIVATE KEY COPIED TO CLIPBOARD! It's now removed from browser.
      // Please copy it to the safe place. It will be used for autheid";
    }
  }, []);

  return (
    <Modal show={visible} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <span className={style.header}>API key generator</span>
      </Modal.Header>
      <Modal.Body>
        <center>
          <FloatingLabel controlId="privateKey" label="Private Key" className="mb-2">
            <Form.Control
              onClick={handleCopyPrivateKey}
              onCopy={handleCopyPrivateKey}
              ref={prvkeyRef}
              as="textarea"
              type="text"
              size="sm"
              placeholder="Private key"
              style={{ height: '200px' }}
            />
          </FloatingLabel>

          <FloatingLabel controlId="publicKey" label="Public Key" className="mb-2">
            <Form.Control
              ref={pubkeyRef}
              as="textarea"
              type="text"
              size="sm"
              placeholder="Public key"
              style={{ height: '200px' }}
            />
          </FloatingLabel>
        </center>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleGenerateCryptoKeys}>Generate new API key pair</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CryptoKeysModal;
