import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import Icon from '../../Icon';
import { Ok } from '../../../shared-components/assets/icons';

import style from './style.module.scss';

interface RegisterProps {
  children?: React.ReactNode;
  show: boolean;
  handleClose?: () => void;
}

const WithdrawModal = ({ children, show, handleClose }: RegisterProps): React.JSX.Element => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <center>
              <Icon icon={Ok} />
            </center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <h1 className={style.h1}>Withdraw status</h1>
            <p></p>
            <h2 className={style.h2}>PENDING</h2>
            {children}
          </center>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-grid gap-2">
            <Button variant="primary" size="lg" onClick={handleClose}>
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WithdrawModal;
