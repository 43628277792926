import { useCallback, useEffect } from 'react';

import { useGraphQL } from '../providers/GraphQLProvider';
import { Method, Service } from '../configuration';
import { API_KEYS } from '../queries/graphql';
import { useApp } from '../providers/AppProvider';

import useSWQuery from './useSWQuery';

const useApiKeys = () => {
  const {
    values: { loggedIn }
  } = useApp();
  const { authorized } = useGraphQL();

  const keys = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    data: {
      query: API_KEYS,
      variables: {}
    },
    returnObjectName: 'apiKeys',
    auto: authorized === true && loggedIn === true
  });

  const refresh = useCallback(() => {
    keys.execute();
  }, [keys]);

  useEffect(() => {
    if (loggedIn === true && authorized === true) {
      refresh();
    }
  }, [authorized, loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return { keys: keys.value, refresh };
};

export default useApiKeys;
