import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import Icon from '../../Icon';
import { Ok } from '../../../shared-components/assets/icons';

interface RegisterProps {
  children?: React.ReactNode;
  show: boolean;
  handleClose?: () => void;
}

const RegisterModal = ({ children, show, handleClose }: RegisterProps): React.JSX.Element => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Icon icon={Ok} />
            Register success
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <h1>Thank you for showing an interest in our service</h1>
            <p></p>
            <h2> We will reach out within 24 hours</h2>
            {children}
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegisterModal;
