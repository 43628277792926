import React from 'react';

import { clsx } from 'clsx';

import style from './style.module.scss';

interface props {
  icon?: string;
  description?: string;
  alt?: string;
  className?: string;
}
const Icon = ({ alt, icon, className, description }: props): React.JSX.Element => {
  return icon !== undefined && icon?.length > 0 ? (
    <img alt={alt} src={icon} className={clsx(style.icon, className)} />
  ) : (
    <>{description}</>
  );
};

export default Icon;
