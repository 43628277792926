import React from 'react';

import CentralFrame from '../../../components/CentralFrame';

const Regulation = () => {
  return (
    <>
      <CentralFrame style={{ maxWidth: '90%' }}>
        <p>
          <center>
            <h2>Regulation</h2>
          </center>
        </p>
        <p>
          Infinipi AB is a licensed payment service provider in Sweden and under supervision of Sweden&apos;s regulator
          Finansinspektionen since May 2021.
        </p>

        <p>
          Infinipi is committed to upholding the highest of standards with regards to knowing our customers, anti-money
          laundering and compliance.
        </p>

        <p>On the website of Sweden&apos;s Finansinspektion you may find a link to our licence.</p>

        <p>
          Should you have any questions with regards to our regulatory licence or any compliance matter, you may reach
          us through hello@infinipi.io.
        </p>
      </CentralFrame>
    </>
  );
};

export default Regulation;
